import styled, { keyframes } from 'styled-components';

// Animação para fazer o container aparecer da esquerda
const slideIn = keyframes`
  0% {
    transform: translateX(-100%); /* Começa fora da tela, à esquerda */
    opacity: 0; /* Começa invisível */
  }
  100% {
    transform: translateX(0); /* Move-se para a posição final */
    opacity: 1; /* Fica visível */
  }
`;

// Animação para desaparecer após 5 segundos
const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%); /* Move-se para fora da tela */
    opacity: 0; /* Fica invisível */
  }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 300px; /* Largura da notificação */
  padding: 15px;
  background-color: #b43333;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 9999;


  .title1
  {
    font-size: 12pt;
    font-weight: 700;
    margin: 0;
  }
  .title1-sensor
  {
    font-size: 9pt;
    margin: 0;
  }

  .title2
  {
    font-size: 12pt;
    font-weight: 700;
    margin: 0;
  }
  .title2-status
  {
    font-size: 9pt;
    margin: 0;
  }

  animation: ${slideIn} 0.5s ease-out forwards, ${slideOut} 0.2s ease-in forwards 5s;

  /* A animação de saída só vai acontecer depois de 5 segundos, quando a notificação desaparecer */
  @keyframes slideIn {
    0% {
      transform: translateX(-100%); /* Começa fora da tela à esquerda */
      opacity: 0;
    }
    100% {
      transform: translateX(0); /* Move-se para a posição visível */
      opacity: 1;
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0); /* Fica visível na posição inicial */
      opacity: 1;
    }
    100% {
      transform: translateX(-100%); /* Move-se para fora da tela */
      opacity: 0; /* Fica invisível */
    }
  }
`;
