import styled from 'styled-components';

export const TitleSensor = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
`;

export const ContentSensor = styled.div`
  color: #545454;
  background-color: #f3f3f3;
  border-radius: 20px;
  padding: 25px;
  width: 100%;
`;

export const Export = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const MonitoringRange = styled.img`
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    opacity: 0.5;
  }
`;

export const Automation = styled.span`
  cursor: pointer;
  margin-right: 5px;

  .icon
  {
    background-color: #00BA88;
    color: #fff;
    width: 23px;
    height: 23px;
    padding: 3px;
    border-radius: 4px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const Notification = styled.img`
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    opacity: 0.5;
  }
`;

export const SensorInfo = styled.div`
  width: 100%;
  min-height: 50px;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
`;

export const ContainerGeneral = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: ${(props) => props.direction == true ? "row" : "column"};
width: 100%;

`;

export const ContainerCardSensor = styled.div`
width:  ${(props) => props.size == true ? "45%" : "90%"};
margin: ${(props) => props.size == true ? "0px 15px 0px 0px" : "15px 0px 15px 0px"};
`;