import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { CardPlan, Container } from './styles/index.style';
import { CiMap } from "react-icons/ci";
import Uploadfile from './uploadfile';

const FloorPlant = ({ unitId }) => {

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <div style={{ width: '100%' }}>
      <div role={'button'} onClick={toggle}>
        <CiMap size={18} />
      </div>
      <Modal
        show={open}
        onHide={toggle}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-align-center'>Upload de planta</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 0, margin: 0 }}>
          <Container>
            <CardPlan>
              <Uploadfile unitId={unitId} />
            </CardPlan>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default observer(FloorPlant);
