import React, { useContext, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  CardMonitoring,
  TitleCardMonitoring,
  TabletIcon,
  TitleBoxOne,
  TitleBoxTwo,
  TagMonitoring,
  Measure,
  IconMeasure,
  LimitMeasure,
  RealMeasure,
  InsideLimitMeasure,
  TagInfo,
  LastReading,
  Ellipse,
  NoRead,
  ButtonSend,
} from "./styles";
import Signal from "../../../../components/signal/signal";
import Lights from "../../../../components/lights/lights";
import Battery from "../../../../components/battery/battery";
import moment from "moment";
import { versionCollector } from "../../../../../components/data/versionCollector";
import { Context } from "../../../../../services/context";
import Toggle from "../../../../../components/toggle";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";
import CardScheduling from "../../../../components/cardscheduling";
import { Slider } from "@material-ui/core";
import { useRoutine } from "../../../../../hooks/routine.hook";

export function MonitoringCard({ item, key, md = 3 }) {
  const { setViewSismogran, setNickname, setIsAutomation, setExpandScheduling } = useContext(Context);
  const { sendCommand, loading } = useRoutine();
  const [toggle, setToggle] = useState(false);
  const [fieldValue, setFieldValue] = useState(100);
  const [openTrigger, setOpenTrigger] = useState(false);

  const toggleButton = () => setToggle(!toggle);

  return (
    <Col md={md} key={key}>
      <div onClick={() => {
        if (!item.automation) {
          setNickname(item.nickname);
          setViewSismogran(item.id);
          setIsAutomation(false);
        }
      }
      } role="button">
        <CardMonitoring automation={item.automation} type={item.monitor_environment} className="mb-3">
          <TitleCardMonitoring>
            {/* {item.monitor_environment === "coletor de automação" && "AAA"} */}
            <TitleBoxOne title={item.nickname}>
              <TabletIcon src="../../../media/icons/tablet.svg" />
              {item.nickname}
            </TitleBoxOne>
            <TitleBoxTwo>
              {
                item.automation
                  ?
                  <div className="d-flex align-self-center mr-3">
                    <HiOutlineCog6Tooth style={{ fontSize: '17pt', }} onClick={() => {
                      setNickname(item.nickname);
                      setViewSismogran(item.id);
                      setIsAutomation(true);
                      setExpandScheduling(false);
                    }
                    } />
                    <LuCalendarClock className="icon-automation" style={{ marginTop: 1 }} onClick={() => {
                      toggleButton();
                    }
                    } />
                  </div>
                  :
                  <div className="d-flex">
                    <Signal
                      signal={item.signal_quality}
                      network={item.network}
                      situation={item.situation}
                    />
                    <Battery
                      level={item.batterylevel}
                      signal={
                        item.situation === "SUCCESS"
                          ? 1
                          : item.situation === "WARNING"
                            ? 2
                            : item.situation === "DANGER"
                              ? 3
                              : item.situation === "DARK"
                                ? 4
                                : 5
                      }
                    />
                  </div>
              }
              <Lights
                message={item.message}
                signal={
                  item.situation === "SUCCESS"
                    ? 1
                    : item.situation === "WARNING"
                      ? 2
                      : item.situation === "DANGER"
                        ? 3
                        : item.situation === "DARK"
                          ? 4
                          : 5
                }
              />
            </TitleBoxTwo>
          </TitleCardMonitoring>
          <TagMonitoring automation={item.automation} type={item.monitor_environment}>
            {!item.monitor_environment
              ? "Coletor de equipamento"
              : "Coletor de ambiente"}
          </TagMonitoring>
          <Row className="m-2">
            {item.sensors?.length === 0 ? (
              <Col md={12}>
                <NoRead>Sem leituras</NoRead>
              </Col>
            ) : (
              item.sensors?.map((sens) => (
                <Col>
                  <Measure>
                    <div className="d-flex justify-content-center">
                      <RealMeasure>
                        <IconMeasure
                          src={
                            sens.name_measure === "Temperatura"
                              ? "../../../media/icons/temperature.svg"
                              : "../../../media/icons/humidity.svg"
                          }
                        />
                        {!sens.value ? 0.0 : sens.value}
                        {!sens.name_unitOfMeasure
                          ? ""
                          : sens.name_measure === "Umidade"
                            ? " %" + sens.name_unitOfMeasure
                            : sens.name_unitOfMeasure}
                      </RealMeasure>

                      {
                        item.automation &&
                        <div className="ml-3" style={{ width: 90, marginTop: 3 }}>
                          <Toggle
                            labelTrue="ON"
                            labelFalse="OFF"
                            value={item.automation.status}
                            // value={openTrigger || item.automation.status}
                            onChange={(e) => {
                              if (e) {
                                setOpenTrigger(e)
                              }
                              else {
                                sendCommand({
                                  id_contract_item: item.id,
                                  btn: e,
                                  setpoint: fieldValue
                                }, () => { });
                              }
                            }
                            }
                          />
                        </div>
                      }
                    </div>

                    <LimitMeasure>
                      <InsideLimitMeasure>
                        <strong>Min</strong>
                        <span>{sens.min}</span>
                      </InsideLimitMeasure>
                      <InsideLimitMeasure>
                        <strong>Máx</strong>
                        <span>{sens.max}</span>
                      </InsideLimitMeasure>
                    </LimitMeasure>
                  </Measure>
                </Col>
              ))
            )}
          </Row>
          <TagInfo automation={item.automation}>
            <strong>Coletor: </strong> {item.serial}{" "}
            <Ellipse src="../../../media/icons/ellipse.svg" />
            <strong>Tag: </strong>{" "}
            {versionCollector.filter((fill) => fill.value === item.version)
              .length === 0
              ? "Nenhuma"
              : versionCollector
                .filter((fill) => fill.value === item.version)
                .map((version) => version.label)}
          </TagInfo>
          <LastReading>
            <strong>Última leitura:</strong>{" "}
            {!item.readed_at
              ? "Sem leituras"
              : moment(item.readed_at).format("DD/MM/YY HH:mm:ss")}
          </LastReading>
        </CardMonitoring>
      </div>

      <Modal show={toggle} onHide={toggleButton} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Cadastro de rotina
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardScheduling automation={item.automation} />
        </Modal.Body>
      </Modal>


      <Modal show={openTrigger} onHide={() => setOpenTrigger(false)} onBackdropClick={() => setOpenTrigger(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            Ativar automação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading}
          <p>Defina o setpoint</p>
          <div style={{ marginTop: 50 }}>
            <Slider
              step={1}
              min={100}
              max={250}
              value={fieldValue}
              valueLabelDisplay="on"
              marks={[
                { value: 100, label: '100' },
                { value: 150, label: '150' },
                { value: 200, label: '200' },
                { value: 250, label: '250' },
              ]}
              onChange={(e, value) => {
                setFieldValue(value);
              }}
            />
          </div>

          <ButtonSend role={'button'} onClick={() => {
            sendCommand({
              id_contract_item: item.id,
              btn: true,
              setpoint: fieldValue
            }, () => setOpenTrigger(false));
          }
          }>
            Enviar comando
          </ButtonSend>
        </Modal.Body>
      </Modal>
    </Col>
  );
}
