import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CardIcon, Container } from "./styles/index.style";
import { GoUpload } from "react-icons/go";
import { StoreContext } from "../../../../stores/inject";
import Settingsensor from "../settingsensor";
import axios from "axios";
import { Prefix } from "../../../../services/prefix";
import api from "../../../../services/api";
import Loading from "../../../loader/loading";

const UploadFile = ({ unitId }) => {
  const { floorPlanStore } = useContext(StoreContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [file, setFile] = useState(null);

  const clearFloorPlan = () => {
    setData(null);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await floorPlanStore.getByUnitId(unitId);
        const modelsData = response.data.models.data;
        setData(Array.isArray(modelsData) && modelsData.length > 0 ? modelsData[0] : null);
      } catch (error) {
        console.error("Erro ao obter os dados:", error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [unitId]);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const fileType = uploadedFile.type;
    if (!["image/png", "image/jpeg", "image/jpg"].includes(fileType)) {
      setImageError("Somente arquivos PNG, JPEG e JPG são permitidos.");
      return;
    }

    const img = new Image();
    img.onload = () => {
      setImage(URL.createObjectURL(uploadedFile));
      setImageError("");
      setFile(uploadedFile); // Armazena o arquivo para o envio
    };
    img.onerror = () => setImageError("Erro ao carregar a imagem.");
    img.src = URL.createObjectURL(uploadedFile);
  };

  const handleClick = () => {
    document.getElementById("file-upload").click();
  };

  const HandleRegister = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("id_unit", unitId);
    formData.append("path", file);

    try {
      const response = await api.post(
        Prefix.replace(/[\\"]/g, '') +
        "/unit_plants", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });


      if (response.status === 200) {
        setFile(null);
        setImage(null);
        setImageError("");
        const newData = await floorPlanStore.getByUnitId(unitId);
        const modelsData = newData.data.models.data;
        setData(Array.isArray(modelsData) && modelsData.length > 0 ? modelsData[0] : null);
      }
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderAction = () => {
    // if (loading) {
    //   return <div>CARREGANDO...</div>;
    // }

    if (!data) {
      return (
        <Container>
          <div className="align-self-center">
            <div className="d-flex justify-content-center">
              <CardIcon>
                <GoUpload className="icon" />
              </CardIcon>
            </div>

            <p className="title">Arraste e solte os arquivos para fazer o envio</p>

            <div className="d-flex justify-content-center">
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="file-upload"
              />
              <button className="button-add" onClick={handleClick} disabled={loading}>
                Selecionar arquivo
              </button>
            </div>
            {imageError && <div style={{ color: "red" }}>{imageError}</div>}

            {image && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img
                    src={image}
                    alt="Imagem carregada"
                    style={{ maxWidth: "200px", maxHeight: "150px", borderRadius: "8px", marginRight: "20px" }}
                  />
                  <button
                    className="button-add"
                    onClick={HandleRegister}
                    disabled={loading}
                  >
                    {loading ? "Salvando..." : "Salvar"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Container>
      );
    }

    return (
      <Container>
        {
          loading && <Loading/>
        }
        {data.path ? <Settingsensor floorPlanId={data.id} unitId={unitId} url={data.path} clearFloorPlan={clearFloorPlan} /> : <div>Sem dados</div>}
      </Container>
    );
  };

  return renderAction();
};

export default observer(UploadFile);