import React from 'react';
import {
  Background,
  Box,
  NotFoundTitle,
  Subtitle,
  Text,
  Button,
  Rows,
} from './NotFoundStyle';
import { Col } from 'react-bootstrap';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../../components/hooks/useFetch';

const NotFound = () => {
  const group = Prefix?.replace(/[\\"]/g, '');
  // const listUser = useFetch(group + '/user');
  // const page =
  //   group == 'customer' &&
  //   response.data.customer.licences[0].active == 1
  // ?
  //   '/gestao/cliente/inicio'
  // :
  //   group == 'customer' &&
  //   response.data.customer.licences[0].active == 0 &&
  //   response.data.customer.licences[1].active == 1
  // ?
  //   '/monitoramento/inicio'
  // : group == 'tech' ?
  //  '/planejamento/inicio'
  // :
  //   '/gestao/inicio'
  // ;
  return (
    <Background>
      <Box>
        <NotFoundTitle>404</NotFoundTitle>
        <Subtitle>Página não encontrada</Subtitle>
        <Text>A página que você tentou acessar não existe...</Text>
        <Rows>
          <Col className="d-flex justify-content-end">
            <Button to="/" background="#41ac98" color="#fff">
              Ir para login
            </Button>
          </Col>
          <Col className="d-flex justify-content-start">
            <Button background="#fff" color="#333">
              Ir para home
            </Button>
          </Col>
        </Rows>
      </Box>
    </Background>
  );
};
export default NotFound;
