import { makeAutoObservable } from "mobx";
import Swal from "sweetalert2";
import { Prefix } from "../services/prefix";
import api from "../services/api";

class SensorStore {
    room;
    sensorSelected;
    contractItems;
    selected;

    constructor() {
        makeAutoObservable(this);
    }

    async getByUnit(id) {
        this.loading = true;
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/contract-items?id_unit=${id}`;
            return await api.get(path);
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async getSensorPointByPlanId(id) {
        this.loading = true; 
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/sensor_points?id_unit_plant=${id}`;
            return await api.get(path);
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async getContractItemsByUnit(id) {
        this.loading = true; 
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/contract-items?id_unit=${id}`;
            const { data, status } = await api.get(path);
            if(status === 200)
                this.contractItems = data.models.data;
            else
                this.contractItems = [];
            
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
            throw e;
        } finally {
            this.loading = false;
        }
    }

    setSelected(object) {
        this.selected = object;
    }

}

export default SensorStore;