import { makeAutoObservable, runInAction } from "mobx";
import Swal from "sweetalert2";
import api from "../services/api";

class UnitStore {
    units = [];
    selected = null;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    // Método para obter unidades
    async getUnits(url) {
        this.loading = true;
        try {
            const { data, status } = await api.get(url);
            if (status === 200 && data.models) {
                runInAction(() => {
                    this.units = data.models;  // Atualiza a referência de `units`
                });
            } else {
                runInAction(() => {
                    this.units = [];
                });
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    // Função para definir um item como selecionado
    setSelected(object) {
        this.selected = object;
    }

    // Função para atualizar o sensor em um unit e gerar uma nova referência
    updateSensorStatus(sensor) {
        runInAction(() => {
            const selectedUnit = this.units.find(unit =>
                unit.contractItems.some(contractItem => contractItem.id === sensor.contractItemId)
            );

            if (selectedUnit) {
                const contractItem = selectedUnit.contractItems.find(contractItem => contractItem.id === sensor.contractItemId);
                if (contractItem) {
                    const monitorProfileAttributes = contractItem.monitor_profile_attruibutes?.find(mpa => mpa.id === sensor.sensorId);

                    if (monitorProfileAttributes) {
                        monitorProfileAttributes.status.status = sensor.status;
                        monitorProfileAttributes.value = sensor.value;
                        monitorProfileAttributes.readed_at = sensor.updatedAt;
                        contractItem.situation = sensor.status;

                        const updatedTime = new Date(sensor.updatedAt);
                        updatedTime.setHours(updatedTime.getHours() + 3);
                        contractItem.readed_at = updatedTime;
                    }
                }
            }

            // Criar nova referência de `units` para garantir que o React reconheça a atualização
            this.units = [...this.units]; // Força uma nova referência
        });
    }
}

export default UnitStore;
