import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Schema from './schema';
import { Context } from '../../../services/context';
import { BackButton, Button } from './editEquipmentsStyle';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as mutateGlobal } from 'swr';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Fontawesome from '../../fontawesome/fontawesome';
import { useFetch } from '../../hooks/useFetch';
import { v4 as uuidv4 } from 'uuid';
import InputSpinner from 'react-bootstrap-input-spinner';

const EditEquipments = ({
  idEquipment,
  idCustomer,
  idContract,
  page,
  rowsPage,
  search,
  orderBy,
  order,
  back,
}) => {
  const { viewEquipments, setViewEquipments } = useContext(Context);
  const [registerData, setRegisterData] = useState({});
  const [valueSelect, setValueSelect] = useState([]);

  const dataEquipments = useFetch(
    !viewEquipments.idEquipment
      ? null
      : Prefix.replace(/[\\"]/g, '') +
      '/contract-items/' +
      viewEquipments.idEquipment,
  );

  const initialExecution = () => {
    if (dataEquipments.data) {
      setRegisterData({
        id_contract: parseInt(dataEquipments.data?.model?.id_contract),
        id_room: parseInt(dataEquipments.data?.model?.id_room),
        id_equipment_model: parseInt(
          dataEquipments.data?.model?.id_equipment_model,
        ),
        serial: dataEquipments.data?.model?.serial,
        out_of_contract: dataEquipments.data?.model?.out_of_contract,
        obs: dataEquipments.data?.model?.obs,
        out_of_use: dataEquipments.data?.model?.out_of_use,
        alienated: parseInt(dataEquipments.data?.model?.alienated),
        additive: parseInt(dataEquipments.data?.model?.additive),
        monitor_environment: dataEquipments.data?.model?.monitor_environment,
        active: dataEquipments.data?.model?.active,
        status: parseInt(dataEquipments.data?.model?.status),
        installation_date: dataEquipments.data?.model?.installation_date,
        instalation_responsable:
          dataEquipments.data?.model?.instalation_responsable,
        preventive_frequency: dataEquipments.data?.model?.preventive_frequency,
        calibration_frequency:
          dataEquipments.data?.model?.calibration_frequency,
        criticity: parseInt(dataEquipments.data?.model?.criticity),
        patrimony: dataEquipments.data?.model?.patrimony,
        identification: dataEquipments.data?.model?.identification,
        nickname: dataEquipments.data?.model?.nickname,
        important: dataEquipments.data?.model?.important,
        monitoring: dataEquipments.data?.model?.monitoring,
        automation: dataEquipments.data?.model?.automation?.active,
        id_equipment_category: parseInt(
          dataEquipments.data?.model?.equipment_model?.id_equipment_category,
        ),
        id_sector: parseInt(dataEquipments.data?.model?.room?.id_sector),
        id_unit: parseInt(dataEquipments.data?.model?.room?.sector?.id_unit),
      });

      if (dataEquipments.data && valueSelect == 0) {
        setValueSelect([
          ...valueSelect,
          {
            value:
              dataEquipments.data?.model?.equipment_model
                ?.id_equipment_category,
            label:
              dataEquipments.data?.model?.equipment_model?.equipment_category
                ?.name,
            nameField: 'id_equipment_category',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.id_equipment_model,
            label: dataEquipments.data?.model?.equipment_model?.name,
            nameField: 'id_equipment_model',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.automation?.active ? true : false,
            label: dataEquipments.data?.model?.automation?.active ? true : false,
            nameField: 'automation',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.monitor_environment,
            label:
              dataEquipments.data?.model?.monitor_environment == 1
                ? 'Coletor de Ambiente'
                : 'Coletor de Equipamento',
            nameField: 'monitor_environment',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.instalation_responsable,
            label: dataEquipments.data?.model?.instalation_responsable,
            nameField: 'instalation_responsable',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.important,
            label:
              dataEquipments.data?.model?.important == 1
                ? 'Sim, dar destaque'
                : 'Não dar destaque',
            nameField: 'important',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.criticity,
            label:
              dataEquipments.data?.model?.criticity == 1
                ? 'Apoio'
                : dataEquipments.data?.model?.criticity == 2
                  ? 'Rotina'
                  : 'Crítico',
            nameField: 'criticity',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.additive,
            label: dataEquipments.data?.model?.additive == 1 ? 'Sim' : 'Não',
            nameField: 'additive',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.alienated,
            label: dataEquipments.data?.model?.alienated == 1 ? 'Sim' : 'Não',
            nameField: 'alienated',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.room?.sector?.id_unit,
            label: dataEquipments.data?.model?.room?.sector?.unit?.name,
            nameField: 'id_unit',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.room?.id_sector,
            label: dataEquipments.data?.model?.room?.sector?.name,
            nameField: 'id_sector',
            keyField: uuidv4(),
          },
          {
            value: dataEquipments.data?.model?.id_room,
            label: dataEquipments.data?.model?.room?.name,
            nameField: 'id_room',
            keyField: uuidv4(),
          },
        ]);
      }
    }
  };

  useEffect(() => {
    initialExecution();
  }, [dataEquipments.data]);

  const collectors = [
    { value: 1, name: 'Coletor de Ambiente' },
    {
      value: 0,
      name: 'Coletor de Equipamento',
    },
  ];

  const feature = [
    { value: 1, name: 'Sim, dar destaque' },
    {
      value: 0,
      name: 'Não dar destaque',
    },
  ];

  const criticity = [
    { value: 1, name: 'Apoio' },
    {
      value: 2,
      name: 'Rotina',
    },
    {
      value: 3,
      name: 'Crítico',
    },
  ];

  const additive = [
    { value: 1, name: 'Sim' },
    {
      value: 0,
      name: 'Não',
    },
  ];

  const allienated = [
    { value: 1, name: 'Sim' },
    {
      value: 0,
      name: 'Não',
    },
  ];

  const HandleSelect = (e, name, keyField) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      setValueSelect(valueSelect.filter((fill) => fill.keyField != keyField));

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      if (name == 'id_equipment_category') {
        api
          .get(
            Prefix.replace(/[\\"]/g, '') +
            '/equipment-categories?id=' +
            e.value,
          )
          .then((r) => {
            setRegisterData({
              ...registerData,
              [name]: e.value,
              monitoring: r.data.models.data[0].equipment_family.monitoring,
            });
          });
      } else {
        setRegisterData({ ...registerData, [name]: e.value });
      }
    } else {
      if (name == 'id_equipment_category') {
        api
          .get(
            Prefix.replace(/[\\"]/g, '') +
            '/equipment-categories?id=' +
            e.value,
          )
          .then((r) => {
            setRegisterData({
              ...registerData,
              [name]: e.value,
              monitoring: r.data.models.data[0].equipment_family.monitoring,
            });
          });
      } else {
        setRegisterData({ ...registerData, [name]: e.value });
      }

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    }
  };

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegisterData({ ...registerData, [name]: value });
  };

  const HandleRegister = () => {
    api
      .put(
        Prefix.replace(/[\\"]/g, '') +
        '/contract-items/' +
        viewEquipments.idEquipment,
        registerData,
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setViewEquipments({
            ...viewEquipments,
            editEquipments: false,
            equipments: true,
          });
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
            '/contract-items?id_contract=' +
            viewEquipments.idContract +
            '&per_page=' +
            viewEquipments.perPage +
            '&page=' +
            viewEquipments.page +
            '&search=' +
            viewEquipments.search +
            '&order_by=' +
            viewEquipments.orderBy +
            '&order=' +
            viewEquipments.order,
          );
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  const HandleShow = () => {
    setViewEquipments({
      ...viewEquipments,
      idEquipment: idEquipment,
      idCustomer: idCustomer,
      idContract: idContract,
      editEquipments: true,
      equipments: false,
      page: page,
      perPage: rowsPage,
      search: search,
      orderBy: orderBy,
      order: order,
    });
  };

  return (
    <>
      {!viewEquipments.editEquipments ? (
        <Link to="#" onClick={HandleShow} className="link_color_datatable">
          <Fontawesome classe="far fa-edit mr-2" />
        </Link>
      ) : (
        <Formik
          initialValues={registerData}
          validationSchema={Schema}
          onSubmit={HandleRegister}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Row className="mt-2">
                <Col>
                  <label>
                    Unidade <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_unit')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    onChange={(e) =>
                      HandleSelect(
                        e,
                        'id_unit',
                        valueSelect
                          .filter((fill) => fill.nameField == 'id_unit')
                          .map((map) => map.keyField),
                      )
                    }
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: '/units',
                      params: `id_customer=${viewEquipments.idCustomer}`,
                    }}
                  />
                  <ErrorMessage
                    name="id_unit"
                    component="span"
                    className="errorMessages"
                  />
                </Col>
                <Col>
                  <label>
                    Setor <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_sector')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    onChange={(e) =>
                      HandleSelect(
                        e,
                        'id_sector',
                        valueSelect
                          .filter((fill) => fill.nameField == 'id_sector')
                          .map((map) => map.keyField),
                      )
                    }
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: '/sectors',
                      params: `id_unit=${values.id_unit}`,
                    }}
                  />
                  <ErrorMessage
                    name="id_sector"
                    component="span"
                    className="errorMessages"
                  />
                </Col>
                <Col>
                  <label>
                    Sala <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_room')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    onChange={(e) =>
                      HandleSelect(
                        e,
                        'id_room',
                        valueSelect
                          .filter((fill) => fill.nameField == 'id_room')
                          .map((map) => map.keyField),
                      )
                    }
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[values.id_sector]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: '/rooms',
                      params: `id_sector=${values.id_sector}`,
                    }}
                  />
                  <ErrorMessage
                    name="id_room"
                    component="span"
                    className="errorMessages"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col className="formStep">
                      <label>
                        Equipamento <span className="required">*</span>
                      </label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField == 'id_equipment_category')
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) => HandleSelect(e, 'id_equipment_category')}
                        placeholder="Selecione..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[valueSelect]}
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/equipment-categories',
                        }}
                      />
                      <ErrorMessage
                        name="id_equipment_category"
                        component="span"
                        className="errorMessages"
                      />
                    </Col>
                    {/* <Col className="formStep">
                      <label>
                        Automação <span className="required">*</span>
                      </label>
                      {console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", valueSelect
                          .filter((fill) => fill.nameField === 'automation')
                          .map((map) => ({
                            value: map.value,
                            label: map.value ? 'Sim' : 'Não',
                          }))[0])}
                      <AsyncPaginate
                        value={valueSelect
                          .filter((fill) => fill.nameField === 'automation')
                          .map((map) => ({
                            value: map.value,
                            label: map.value ? 'Sim' : 'Não',
                          }))[0]}
                        loadOptions={() => Promise.resolve({
                          options: [
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ],
                        })}
                        onChange={(e) => 
                        {
                          // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", e)
                          HandleSelect(e, 'automation');
                        }
                        }
                        placeholder="Selecione..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[valueSelect]}
                        styles={colourStyles}
                      />
                      <ErrorMessage
                        name="id_equipment_category"
                        component="span"
                        className="errorMessages"
                      />
                    </Col> */}
                  </Row>
                </Col>
                {valueSelect.filter(
                  (fill) => fill.nameField == 'id_equipment_category',
                ).length > 0 ? (
                  <Col>
                    <div className="formStep">
                      <label>
                        Modelo <span className="required">*</span>
                      </label>
                      <AsyncPaginate
                        value={valueSelect
                          .filter(
                            (fill) => fill.nameField == 'id_equipment_model',
                          )
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        onChange={(e) =>
                          HandleSelect(
                            e,
                            'id_equipment_model',
                            valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField == 'id_equipment_model',
                              )
                              .map((map) => map.keyField),
                          )
                        }
                        placeholder="Selecione..."
                        components={{ NoOptionsMessage }}
                        cacheUniqs={[valueSelect]}
                        styles={colourStyles}
                        additional={{
                          page: 1,
                          endpoint: '/equipment-models',
                          params: `id_equipment_category=${values?.id_equipment_category}`,
                        }}
                      />
                      <ErrorMessage
                        name="id_equipment_model"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="formStep">
                    <label>
                      Serial<span className="required"> * </span>
                    </label>
                    <Field
                      type="text"
                      name="serial"
                      placeholder="Digite um serial"
                      onChange={HandleChange}
                    />
                    <ErrorMessage
                      name="serial"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="formStep">
                    <label>
                      Data de Instalação <span className="required"> * </span>
                    </label>
                    <Field
                      type="date"
                      name="installation_date"
                      onChange={HandleChange}
                    />

                    <ErrorMessage
                      name="installation_date"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>

                {values.monitoring == 1 && (
                  <Col style={{ zIndex: 10 }}>
                    <div className="formStep">
                      <label class="important">Tipo de coletor:</label>
                      <Select
                        value={valueSelect
                          .filter(
                            (fill) => fill.nameField == 'monitor_environment',
                          )
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        styles={colourStyles}
                        components={{ NoOptionsMessage }}
                        onChange={(e) =>
                          HandleSelect(
                            e,
                            'monitor_environment',
                            valueSelect
                              .filter(
                                (fill) =>
                                  fill.nameField == 'monitor_environment',
                              )
                              .map((map) => map.keyField),
                          )
                        }
                        placeholder="Selecione..."
                        options={collectors.map((map) => {
                          return { value: map.value, label: map.name };
                        })}
                      />
                      <ErrorMessage
                        name="monitor_environment"
                        component="span"
                        className="errorMessages"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="formStep">
                    <label>Responsável:</label>

                    <AsyncPaginate
                      value={valueSelect
                        .filter(
                          (fill) => fill.nameField == 'instalation_responsable',
                        )
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      onChange={(e) =>
                        HandleSelect(
                          e,
                          'instalation_responsable',
                          valueSelect
                            .filter(
                              (fill) =>
                                fill.nameField == 'instalation_responsable',
                            )
                            .map((map) => map.keyField),
                        )
                      }
                      placeholder="Selecione..."
                      components={{ NoOptionsMessage }}
                      cacheUniqs={[valueSelect]}
                      styles={colourStyles}
                      additional={{
                        page: 1,
                        endpoint: '/customer-employees',
                        params: `id_customer=${viewEquipments.idCustomer}`,
                      }}
                    />

                    <ErrorMessage
                      name="instalation_responsable"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                {(registerData.preventive_frequency ||
                  registerData.preventive_frequency == 0) && (
                    <Col>
                      <div className="formStep">
                        <label class="important">Frequência de Preventiva:</label>

                        <InputSpinner
                          type="number"
                          name="preventive_frequency"
                          variant="success"
                          size="md"
                          precision={2}
                          min={0}
                          value={
                            !registerData.preventive_frequency
                              ? 0
                              : registerData.preventive_frequency
                          }
                          onChange={(e) =>
                            setRegisterData({
                              ...registerData,
                              ['preventive_frequency']: e,
                            })
                          }
                        />
                        <ErrorMessage
                          name="preventive_frequency"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  )}
                {(registerData.calibration_frequency ||
                  registerData.calibration_frequency == 0) && (
                    <Col>
                      <div className="formStep">
                        <label class="important">Frequência de Calibração:</label>

                        <InputSpinner
                          type="number"
                          name="calibration_frequency"
                          variant="success"
                          size="md"
                          precision={2}
                          min={0}
                          value={
                            !registerData.calibration_frequency
                              ? 0
                              : registerData.calibration_frequency
                          }
                          onChange={(e) =>
                            setRegisterData({
                              ...registerData,
                              ['calibration_frequency']: e,
                            })
                          }
                        />

                        <ErrorMessage
                          name="calibration_frequency"
                          component="span"
                          className="errorMessages"
                        />
                      </div>
                    </Col>
                  )}
                <Col>
                  <div className="formStep">
                    <label>
                      Destaque <span className="required"> * </span>
                    </label>
                    <Select
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'important')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      styles={colourStyles}
                      components={{ NoOptionsMessage }}
                      onChange={(e) =>
                        HandleSelect(
                          e,
                          'important',
                          valueSelect
                            .filter((fill) => fill.nameField == 'important')
                            .map((map) => map.keyField),
                        )
                      }
                      placeholder="Selecione..."
                      options={feature.map((map) => {
                        return { value: map.value, label: map.name };
                      })}
                    />
                    <ErrorMessage
                      name="important"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="formStep">
                    <label>
                      Apelido <span className="required"> * </span>
                    </label>
                    <Field type="text" name="nickname" onKeyUp={HandleChange} />
                    <ErrorMessage
                      name="nickname"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="formStep">
                    <label>
                      Criticidade <span className="required">* </span>
                    </label>
                    <Select
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'criticity')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      styles={colourStyles}
                      components={{ NoOptionsMessage }}
                      onChange={(e) =>
                        HandleSelect(
                          e,
                          'criticity',
                          valueSelect
                            .filter((fill) => fill.nameField == 'criticity')
                            .map((map) => map.keyField),
                        )
                      }
                      placeholder="Selecione..."
                      options={criticity.map((map) => {
                        return { value: map.value, label: map.name };
                      })}
                    />
                    <ErrorMessage
                      name="criticity"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="formStep">
                    <label>
                      É um aditivo? <span className="required"> * </span>
                    </label>
                    <Select
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'additive')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      styles={colourStyles}
                      components={{ NoOptionsMessage }}
                      onChange={(e) =>
                        HandleSelect(
                          e,
                          'additive',
                          valueSelect
                            .filter((fill) => fill.nameField == 'additive')
                            .map((map) => map.keyField),
                        )
                      }
                      placeholder="Selecione..."
                      options={additive.map((map) => {
                        return { value: map.value, label: map.name };
                      })}
                    />
                    <ErrorMessage
                      name="additive"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={4}>
                  <div className="formStep">
                    <label>
                      Patrimônio <span className="required"> * </span>
                    </label>
                    <Field
                      type="text"
                      name="patrimony"
                      onKeyUp={HandleChange}
                    />
                    <ErrorMessage
                      name="patrimony"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="formStep">
                    <label>
                      Identificação <span className="required"> * </span>
                    </label>
                    <Field
                      type="text"
                      name="identification"
                      onKeyUp={HandleChange}
                    />
                    <ErrorMessage
                      name="identification"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="formStep">
                    <label>
                      Alienado <span className="required">*</span>
                    </label>
                    <Select
                      value={valueSelect
                        .filter((fill) => fill.nameField == 'alienated')
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      styles={colourStyles}
                      components={{ NoOptionsMessage }}
                      onChange={(e) =>
                        HandleSelect(
                          e,
                          'alienated',
                          valueSelect
                            .filter((fill) => fill.nameField == 'alienated')
                            .map((map) => map.keyField),
                        )
                      }
                      placeholder="Selecione..."
                      options={allienated.map((map) => {
                        return { value: map.value, label: map.name };
                      })}
                    />
                    <ErrorMessage
                      name="alienated"
                      component="span"
                      className="errorMessages"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="mt-5 d-flex justify-content-end">
                  {!viewEquipments.equipments && (
                    <BackButton type="button" onClick={back}>
                      Voltar
                    </BackButton>
                  )}
                </Col>
                <Col className="mt-5 d-flex justify-content-start">
                  <Button type="submit">Atualizar</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default EditEquipments;
