import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { ButtonRemove, ButtonSavePoint, Container, ScrollSensors } from "./styles/index.style";
import { StoreContext } from '../../../../stores/inject'
import api from "../../../../services/api";
import { Prefix } from "../../../../services/prefix";
import Swal from "sweetalert2";
import Loading from "../../../loader/loading";

const SettingSensor = ({ floorPlanId, unitId, url, clearFloorPlan }) => {
  const canvasRef = useRef(null);

  const { sensorStore, floorPlanStore } = useContext(StoreContext);
  const [clickPosition, setClickPosition] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [savedPositions, setSavedPositions] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProfileAttribute, setSelectedProfileAttribute] = useState();
  const [sensorName, setSensorName] = useState("");

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value) {
      const [sensorId, profileId] = value.split("-");
      setSelectedSensor(sensorId);
      setSelectedProfileAttribute(profileId);

      const selected = sensorStore.contractItems?.find(sensor => sensor.id === parseInt(sensorId));
      if (selected) {
        setSensorName(selected.nickname);
      }
    } else {
      setSelectedSensor("");
      setSelectedProfileAttribute("");
      setSensorName("");
    }
  };



  useEffect(() => {
    if (!url) return;

    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImage(img);
      setImageLoaded(true);
    };

    img.onerror = () => {
      console.error("Erro ao carregar a imagem:", url);
    };
  }, [url]);

  useEffect(() => {
    (async () => {
      await sensorStore.getContractItemsByUnit(unitId);

      const responsePoints = await sensorStore.getSensorPointByPlanId(floorPlanId);
      const mappedPositions = responsePoints.data.models.data.map(sensor => ({
        position: sensor,
        sensor: sensor.id,
        name: sensor.contract_item_nickname
      }));

      // Atualizando os estados com os dados mapeados
      setSavedPositions(mappedPositions);
    })();
  }, [sensorStore.loading, floorPlanId]);

  const handleClick = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;


    const tolerance = 15;

    const calculateDistance = (x1, y1, x2, y2) => {
      return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    };

    const existingSensor = savedPositions.find(
      (position) => calculateDistance(position.position.x, position.position.y, x, y) <= tolerance
    );

    if (existingSensor) {
      Swal.fire({
        icon: "warning",
        title: "Ponto já possui um sensor",
        text: `Sensor ID: ${existingSensor.sensor}`,
        showCancelButton: true,
        confirmButtonText: "Você deseja excluir?",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(Prefix.replace(/[\\"]/g, "") + `/sensor_points/${existingSensor.sensor}`,)
            .then(() => {
              const updatedPositions = savedPositions.filter(
                (position) => position.sensor !== existingSensor.sensor
              );
              setSavedPositions(updatedPositions);
              Swal.fire({
                icon: "success",
                title: "Uow!",
                text: "Excluído com sucesso!",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Fechar",
                allowOutsideClick: false,
              })
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data.message,
                confirmButtonText: "Tentar novamente",
              });
            });
        }
      });
    } else {
      setClickPosition({ x, y });
      setShowToast(true);
    }
  };

  const HandlerRemoveFloorPlan = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Você tem certeza?",
      text: "Esta ação é irreversível!",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar"
    });

    if (!result.isConfirmed) {
      return;
    }

    setLoading(true);
    try {
      const response = await api.delete(
        Prefix.replace(/[\\"]/g, '') + `/unit_plants/${floorPlanId}`, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }
      );

      if (response.status === 200) {
        clearFloorPlan();
        Swal.fire({
          icon: "success",
          title: "Uow!",
          text: response.data.message.text,
          confirmButtonText: "Continuar"
        });
      }
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (clickPosition && selectedSensor) {
      try {
        const request =
        {
          id_monitor_profile_attribute: parseInt(selectedProfileAttribute),
          id_unit_plant: floorPlanId,
          x: clickPosition.x,
          y: clickPosition.y
        }
        const response = await api.post(
          Prefix.replace(/[\\"]/g, '') + `/sensor_points`, request);

        if (response.status === 200) {
          setSavedPositions((prevPositions) => [
            ...prevPositions,
            { position: clickPosition, sensor: selectedSensor, name: sensorName },
          ]);
          setShowToast(false);
          Swal.fire({
            icon: "success",
            title: "Uow!",
            text: response.data.message.text,
            confirmButtonText: "Continuar"
          });
        }
      } catch (error) {
        console.error("Erro ao salvar os dados:", error);
        const errorMessage = error?.response?.data?.message || "Erro desconhecido. Tente novamente.";
  
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
          confirmButtonText: "Fechar",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!imageLoaded || !image) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    image.width = 1200;
    image.height = 760;

    const canvasWidth = image.width;
    const canvasHeight = image.height;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    const adjustCoordinates = (x, y) => {
      const adjustedX = (x / image.width) * canvas.width;
      const adjustedY = (y / image.height) * canvas.height;
      return { adjustedX, adjustedY };
    };

    savedPositions.forEach((item, index) => {
      const { position, sensor, name } = item;
      const { adjustedX, adjustedY } = adjustCoordinates(position.x, position.y);

      if (adjustedX >= 0 && adjustedX <= canvasWidth && adjustedY >= 0 && adjustedY <= canvasHeight) {
        ctx.beginPath();
        ctx.arc(adjustedX, adjustedY, 10, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(0, 255, 0, 1)";
        ctx.fill();

        const sensorLabel = sensor ? `Sensor ${index + 1}: ${sensor}` : `Sensor ${index + 1}: vazio`;

        ctx.font = "14px Arial";
        ctx.fillStyle = "#000";

        const textWidth = ctx.measureText(name ?? "Erro").width;
        const padding = 10;

        ctx.fillStyle = "rgba(236, 146, 68, 0.8)";
        ctx.beginPath();
        ctx.moveTo(adjustedX + 15 - padding, adjustedY - 8 - padding);
        ctx.lineTo(adjustedX + 15 + textWidth + padding, adjustedY - 8 - padding);
        ctx.lineTo(adjustedX + 15 + textWidth + padding, adjustedY + 8 + padding);
        ctx.lineTo(adjustedX + 15 - padding, adjustedY + 8 + padding);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = "#000";
        ctx.fillText(name ?? "Erro", adjustedX + 15, adjustedY);
      }
    });

    if (clickPosition) {
      const { adjustedX, adjustedY } = adjustCoordinates(clickPosition.x, clickPosition.y);

      ctx.beginPath();
      ctx.arc(adjustedX, adjustedY, 10, 0, Math.PI * 2);
      ctx.fillStyle = "rgba(0, 255, 0, 1)";
      ctx.fill();
    }
  }, [imageLoaded, image, savedPositions, clickPosition, selectedSensor]);


  const renderSensors = () => {
    if (sensorStore.loading)
      return <div>Carregando...</div>

    return (
      <ScrollSensors>
        <h3>Sensores Disponíveis:</h3>
        <ul>
          {sensorStore.contractItems?.map((sensor, index) => (
            <li key={index} style={{ marginBottom: "10px" }}>
              {sensor?.nickname}
            </li>
          ))}
        </ul>
      </ScrollSensors>
    )
  }

  return (
    <Container>
      <canvas
        ref={canvasRef}
        onClick={handleClick}
        style={{
          cursor: "pointer",
          zIndex: 1,
          borderRadius: 11,
          alignSelf: "center",
        }}
      />
      {
        sensorStore?.loading && <Loading />
      }
      {showToast && clickPosition && (
        <div
          style={{
            position: "absolute",
            top: clickPosition.y + 20,
            left: clickPosition.x + 20,
            background: "#fff",
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
          }}
        >
          <p>Coordenadas: X: {clickPosition.x}, Y: {clickPosition.y}</p>
          <div>
            <select onChange={handleSelectChange} value={`${selectedSensor}-${selectedProfileAttribute}`}>
              <option value="">Selecione um sensor</option>
              {sensorStore.contractItems?.map((sensor) => (
                <optgroup key={sensor.id} label={sensor.nickname}>
                  {sensor.monitor_profile_attruibutes?.map((profile) => (
                    <option key={profile.id} value={`${sensor.id}-${profile.id}`}>
                      {`${sensor.nickname}: ${profile.profile_attribute.unit_of_measure.measure.name}`}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>

          <ButtonSavePoint onClick={handleSave}>Salvar</ButtonSavePoint>
        </div>

      )}

      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          background: "#fff",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
          width: "200px",
        }}
      >
        {renderSensors()}
        <ButtonRemove onClick={HandlerRemoveFloorPlan}>
          {
            loading
              ?
              "Excluindo..."
              :
              "Excluir planta"
          }
        </ButtonRemove>
      </div>
    </Container>
  );
};

export default observer(SettingSensor);