import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.isToggled ? props.background ?? '#EA7325' : '#5F6368')};
    border-radius: 20px;
    transition: background-color 0.3s ease;
`;

export const Switch = styled.div`
    width: 40px;
    height: 20px;
    background-color: #fff;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    margin-right: 4px;

    &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: ${(props) => (props.isToggled ? '20px' : '2px')};
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${(props) => (props.isToggled ? props.background ?? '#EA7325' : '#5F6368')};
        transition: left 0.3s ease;
    }
`;

export const Label = styled.span`
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
    width: 30px
`;