import download from 'downloadjs'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import Swal from 'sweetalert2'
import Loading from '../../../components/loader/loading'
import { NoOptionsMessage } from '../../../components/selectAsync/NoOptionsMessage'
import { colourStyles } from '../../../components/selectAsync/colourStyles'
import api from '../../../services/api'
import { Prefix } from '../../../services/prefix'
import { ButtonSend, ExportContent, InputDate } from './exportReportStyle'

const ExportReport = ({ sensor, idContractItem }) => {
  const [registerData, setRegisterData] = useState({
    type: '',
    file_type: '',
    month: '',
    year: new Date().getFullYear().toString(),
    epoch: '',
    epoch_end: '',
  })
  const [valueSelect, setValueSelect] = useState([])
  const [loader, setLoader] = useState(false)

  const HandleSelect = (e, name) => {
    if (valueSelect.filter(fill => fill.nameField === name).length > 0) {
      /* eslint-disable array-callback-return */

      valueSelect
        .filter(fill => fill.nameField === name)
        .map(map => {
          let valueIndice = valueSelect.indexOf(map)
          valueSelect.splice(valueIndice)
        })

      setValueSelect(prev => {
        return [...prev, { value: e.value, label: e.label, nameField: name }]
      })
      setRegisterData({ ...registerData, [name]: e.value })
    } else {
      setValueSelect(prev => {
        return [...prev, { value: e.value, label: e.label, nameField: name }]
      })
      setRegisterData({ ...registerData, [name]: e.value })
    }
  }

  useEffect(() => {
    setRegisterData({
      ...registerData,
      type: registerData.type,
      file_type: '',
      month: '',
      year: new Date().getFullYear().toString(),
      epoch: '',
      epoch_end: '',
    })
  }, [registerData.type])

  useEffect(() => {
    setValueSelect(prev => {
      const currentYear = new Date().getFullYear().toString()
      return [...prev, { value: currentYear, label: currentYear, nameField: 'year' }]
    })
  }, [])

  const types = [
    { value: 'day', name: 'Diário' },
    { value: 'month', name: 'Mensal' },
  ]

  const month = [
    { value: '1', name: 'Janeiro' },
    { value: '2', name: 'Fevereiro' },
    { value: '3', name: 'Março' },
    { value: '4', name: 'Abril' },
    { value: '5', name: 'Maio' },
    { value: '6', name: 'Junho' },
    { value: '7', name: 'Julho' },
    { value: '8', name: 'Agosto' },
    { value: '9', name: 'Setembro' },
    { value: '10', name: 'Outubro' },
    { value: '11', name: 'Novembro' },
    { value: '12', name: 'Dezembro' },
  ]

  function getYears() {
    const years = []
    const maxYear = new Date().getFullYear()
    const minYear = 2015

    for (let year = minYear; year <= maxYear; year++) {
      years.push({
        value: year.toString(),
        label: year.toString(),
      })
    }

    return years
  }

  const fileType = [
    { value: 'pdf', name: 'PDF' },
    { value: 'csv', name: 'Excel (CSV)' },
    { value: 'xls', name: 'Excel (XLS)' },
    { value: 'xlsx', name: 'Excel (XLSX)' },
  ]

  function ExportTool(e) {
    setLoader(true)

    const url =
      registerData?.type === 'day' && registerData?.file_type === 'pdf'
        ? '/sismogran/readings/export/profile/' +
          sensor +
          '?epoch=' +
          registerData?.epoch +
          '&epoch_end=' +
          registerData?.epoch_end
        : registerData?.type === 'day' &&
          (registerData?.file_type === 'csv' ||
            registerData?.file_type === 'xls' ||
            registerData?.file_type === 'xlsx')
        ? '/sismogran/readings/export/profile/' +
          sensor +
          '/excel?epoch=' +
          registerData?.epoch +
          '&epoch_end=' +
          registerData?.epoch_end +
          '&type=' +
          registerData?.file_type
        : registerData?.type === 'month'
        ? '/sismogran/readings/export/profile/' +
          sensor +
          '/avg/month?month=' +
          registerData?.month +
          '&year=' +
          registerData?.year
        : '/sismogran/readings/export/' + idContractItem + '/avg/year?year=' + registerData?.year

    const extension = registerData?.file_type === '' ? 'pdf' : registerData?.file_type

    const nameReport =
      registerData?.type === 'day'
        ? 'relatorio-diario.' + extension
        : registerData?.type === 'month'
        ? 'relatorio-mensal.' + extension
        : 'relatorio-anual.' + extension

    api
      .get(Prefix?.replace(/[\\"]/g, '') + url, {
        responseType: 'blob',
      })
      .then(response => {
        const content = response.headers['content-type']
        download(response.data, nameReport, content)

        Swal.fire({
          icon: 'success',
          title: 'Feito!',
          text: 'Download realizado com sucesso!',
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false)
        })
      })
      .catch(error =>
        Swal.fire({
          icon: 'error',
          title: 'Desculpe...',
          text: 'Não foi possível gerar este relatório, tente novamente mais tarde...',
          confirmButtonText: 'Fechar',
        }).then(() => {
          setLoader(false)
        }),
      )
  }

  return (
    <>
      {loader && <Loading />}
      <ExportContent className="customerStep">
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Select
              value={
                valueSelect
                  .filter(fill => fill.nameField === 'type')
                  .map(map => {
                    return {
                      value: map.value,
                      label: map.label,
                    }
                  })[0]
              }
              styles={colourStyles}
              components={{ NoOptionsMessage }}
              onChange={e => HandleSelect(e, 'type')}
              placeholder="Periodicidade..."
              options={types.map(state => {
                return { value: state.value, label: state.name }
              })}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          {registerData?.type === 'day' && (
            <>
              <Col>
                <InputDate
                  type="date"
                  name="epoch"
                  className="form-control"
                  onChange={e =>
                    setRegisterData({
                      ...registerData,
                      epoch: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  type="date"
                  name="epoch_end"
                  className="form-control"
                  onChange={e =>
                    setRegisterData({
                      ...registerData,
                      epoch_end: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <Select
                  value={
                    valueSelect
                      .filter(fill => fill.nameField === 'file_type')
                      .map(map => {
                        return {
                          value: map.value,
                          label: map.label,
                        }
                      })[0]
                  }
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={e => HandleSelect(e, 'file_type')}
                  placeholder="Tipo de arquivo..."
                  options={fileType.map(state => {
                    return { value: state.value, label: state.name }
                  })}
                />
              </Col>
            </>
          )}

          {registerData?.type === 'month' && (
            <>
              <Col>
                <Select
                  value={
                    valueSelect
                      .filter(fill => fill.nameField === 'month')
                      .map(map => {
                        return {
                          value: map.value,
                          label: map.label,
                        }
                      })[0]
                  }
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={e => HandleSelect(e, 'month')}
                  placeholder="Mês..."
                  options={month.map(state => {
                    return { value: state.value, label: state.name }
                  })}
                />
              </Col>
              <Col>
                <Select
                  value={
                    valueSelect
                      .filter(fill => fill.nameField === 'year')
                      .map(map => {
                        return {
                          value: map.value,
                          label: map.label,
                        }
                      })[0]
                  }
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={e => HandleSelect(e, 'year')}
                  placeholder="Ano..."
                  options={getYears()}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-4">
            <ButtonSend onClick={ExportTool}>Gerar relatório</ButtonSend>
          </Col>
        </Row>
      </ExportContent>
    </>
  )
}
export default ExportReport
