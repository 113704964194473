import { HubConnectionBuilder, HubConnection, HttpTransportType, LogLevel } from '@microsoft/signalr';
const { REACT_APP_API_SIGNAIR } = process.env;


export function connection(path) {
  return new HubConnectionBuilder()
    .withUrl(`${REACT_APP_API_SIGNAIR}/${path}`, {  
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
    }) 
    .configureLogging(LogLevel.None) 
    .withAutomaticReconnect()
    .build();  
} 