import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 11px;
    margin-top: 20px;
`;

export const Title = styled.h3`
    text-align: center;
    margin: 10px;
`;

export const EmptyList = styled.p`
    text-align: center;
    margin: 10px;
    font-size: 22pt;
    font-weight: bold;
`;

export const CardWeeks = styled.div`
    text-align: center;
    margin: 25px;
    height: 50px;
    width: 50px;
    background-color: ${(props) => (props.active ? "#00BA88" : "#8B8B8B")};
    border-radius: 50%;
    margin: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ButtonSave = styled.button`
    text-align: center;
    width: 300px;
    background-color: #00BA88;
    border-radius: 60px;
    padding: 10px;
    margin: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
`;

export const ContentRoutine = styled.div`
    padding: 10px;
    border-radius: 11px;
    background-color: #f3f3f3;
    margin: 10px;

    .background
    {
        border-radius: 11px;
        background-color: #fff;
    }

    .dates
    {
        background-color: #00ba88;
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
        width: 100%;
    }

    .header
    {
        padding: 10px;
        color: #fff;
        display: flex;
    }

    .bar
    {
        height: 15px;
        width: 2px;
        background-color: #fff;
        margin: 5px;
    }

    .bar-bottom
    {
        height: 15px;
        width: 2px;
        background-color: #00000078;
        margin: 5px;
    }


    .icon-action
    {
        margin-left: 15px;
        font-size: 15pt;
        cursor: pointer;
    }
`;
