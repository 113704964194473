import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fontawesome from "../../../../components/fontawesome/fontawesome";
import {
  LocationMonitoring,
  IconLocationMonitoring,
  IconArrowLocation,
  Location,
  TotalItems,
  Button,
  Situations,
  Status,
  Chip,
  StatusText,
  ButtonIcon,
  Rotate,
  CardRoom,

} from "./styles";
import { useFetch } from "../../../../../components/hooks/useFetch";
import { Prefix } from "../../../../../services/prefix";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import Loading from "../../../../../components/loader/loading";
import { MonitoringCard } from "../MonitoringCard";
import { StoreContext } from "../../../../../stores/inject";
import { observer } from "mobx-react-lite";
import RoomMonitoring from "../RoomMonitoring";

const RoomMonitoringV2 = ({
  idUnit,
  unit,
  nameUnit,
  idRoom,
  idSector,
  customerName,
  idCustomer,
  filter,
  toggleModalPlan,
  sensors,
  key,
  openModalPlan,
  setContractItems,
  setAllRoomsUnits,
  contractItems
}) => {

  const { sensorStore, roomStore } = useContext(StoreContext);
  const [opens, setOpens] = useState([]);

  const [allRooms, setAllRooms] = useState({});

  function handleOpen(id) {
    setOpens((state) =>
      state.includes(id) ? state.filter((it) => it !== id) : [...state, id]
    );
  }

  const handleChipClick = (situation) => {
    // setFilter((prevFilter) => (prevFilter === situation ? null : situation));
  };

  function isOpen(id) {
    return opens.includes(id);
  }

  const buttonRef = useRef(null);

  const params = Object.entries({
    sector: idSector,
    room: idRoom,
  })
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const path = `${Prefix.replace(
    /[\\"]/g,
    ""
  )}/sismogran/map/units/${idUnit}/rooms?${params}`;

  const listRooms = useFetch(path);


  const reduceSituations = (equipments) => {
    const initial = {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0,
    };

    return equipments?.reduce((acc, equipment) => {
      const sensors = equipment.monitor_profile_attruibutes;

      if (Array.isArray(sensors)) {
        let hasDark = false;
        let hasSuccess = false;
        let hasWarning = false;
        let hasDanger = false;

        sensors.forEach(sensor => {
          const status = sensor.status.status;

          if (status) {
            if (status === "DARK") {
              hasDark = true;
            } else if (status === "SUCCESS") {
              hasSuccess = true;
            } else if (status === "WARNING") {
              hasWarning = true;
            } else if (status === "DANGER") {
              hasDanger = true;
            }
          }
        });

        if (hasDark) {
          acc.DARK += 1;
        } else if (hasSuccess) {
          acc.SUCCESS += 1;
        } else if (hasWarning) {
          acc.WARNING += 1;
        } else if (hasDanger) {
          acc.DANGER += 1;
        }
      }

      return acc;
    }, initial);
  };

  const overallSituations = useMemo(() => {
    if (!contractItems.find(x => x.id === unit.id) || contractItems.find(x => x.id === unit.id).contractItems.length === 0) return null;

    return reduceSituations(contractItems.find(x => x.id === unit.id).contractItems);
  }, [contractItems]);

  useEffect(() => {
    if (!listRooms.data?.models) return;

    setAllRooms((state) => ({ ...state, [idUnit]: listRooms.data?.models }));
    setAllRoomsUnits((state) => [
      ...(state || []), // Mantém os itens já existentes no array
      ...(listRooms.data?.models || []) // Adiciona os novos itens
    ]);
    return () => setAllRooms((state) => ({ ...state, [idUnit]: [] }));
  }, [idUnit, listRooms.data, setAllRooms]);

  if (!listRooms.data) return <Loading />;

  const open = isOpen(idRoom);

  return (
    <Col sm={12} style={{ margin: 5 }}>
      <LocationMonitoring>
        <Location className="d-flex">
          {nameUnit}

          {
            unit.unit_plant
            &&
            <div>
              <Button onClick={() => {
                sensorStore.setSelected(unit);
                roomStore.setSelected(unit?.contractItems);
                setContractItems(unit?.contractItems);
                toggleModalPlan();
              }}>
                <LiaMapMarkedAltSolid className="align-self-center" size={24} />
              </Button>
            </div>
          }
        </Location>

        <LocationMonitoring
          style={{ justifyContent: "center", paddingLeft: 0 }}
        >
          <Chip
            active={filter === "SUCCESS"}
            situation="SUCCESS"
            onClick={() => handleChipClick("SUCCESS")}
          >
            <Status src="/media/icons/green-light.svg" />
            <StatusText>
              {overallSituations?.SUCCESS || "--"}
            </StatusText>
            <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
          </Chip>

          <Chip
            active={filter === "WARNING"}
            situation="WARNING"
            onClick={() => handleChipClick("WARNING")}
          >
            <Status src="/media/icons/yellow-light.svg" />
            <StatusText>
              {overallSituations?.WARNING || "--"}
            </StatusText>
            <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
          </Chip>

          <Chip
            active={filter === "DANGER"}
            situation="DANGER"
            onClick={() => handleChipClick("DANGER")}
          >
            <Status src="/media/icons/red-light.svg" />
            <StatusText>
              {overallSituations?.DANGER || "--"}
            </StatusText>
            <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
          </Chip>

          <Chip
            active={filter === "DARK"}
            situation="DARK"
            onClick={() => handleChipClick("DARK")}
          >
            <Status src="/media/icons/black-light.svg" />
            <StatusText>
              {overallSituations?.DARK || "--"}
            </StatusText>
            <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
          </Chip>
        </LocationMonitoring>

        <TotalItems>
          <Button onClick={() => handleOpen(idRoom)}>
            <strong>
              {unit.total}
            </strong>
            <div style={{ marginLeft: 4 }}>Equipamento(s)</div>
            <ButtonIcon>
              <Rotate>
                <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
              </Rotate>
            </ButtonIcon>
          </Button>
        </TotalItems>
      </LocationMonitoring>
      {open && (
        <CardRoom>
          <RoomMonitoring
            idUnit={unit.id}
            nameUnit={unit.name}
            showNameUnit
            key={key}
            idCustomer={idCustomer}
            idSector={idSector}
            idRoom={idRoom}
            customerName={customerName}
            setAllRooms={setAllRooms}
            filter={filter}
            toggleModalPlan={toggleModalPlan}
          />
        </CardRoom>
      )}
    </Col>
  );
};
export default observer(RoomMonitoringV2);
