import React, { useState, useEffect } from 'react';
import { Container, Switch, Label } from './styles/index.style';

const Toggle = ({ background, labelTrue, labelFalse, onChange, value }) => {
    const [isToggled, setIsToggled] = useState(value || false);

    useEffect(() => {
        setIsToggled(value);
    }, [value]);

    const handleToggle = () => {
        const newValue = !isToggled;
        setIsToggled(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <Container isToggled={isToggled} background={background} onClick={handleToggle}>
            <Label>{isToggled ? labelTrue : labelFalse}</Label>
            <Switch background={background} isToggled={isToggled} />
        </Container>
    );
};

export default Toggle;