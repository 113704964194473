import styled from "styled-components";

export const Location = styled.div`
  width: 40%;
  max-width: 40%;
  min-width: 40%;
`;

export const TotalItems = styled.div``;

export const LocationMonitoring = styled.div`
  width: 100%;
  min-height: 35px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const IconLocationMonitoring = styled.img`
  margin: 0 5px;
  width: 20px;
`;

export const IconArrowLocation = styled.img`
  margin: 0 5px;
  width: 15px;
`;

export const Button = styled.button`
  background-color: transparent;

  border: 0;

  display: flex;
`;

export const ButtonIcon = styled.div`
  border-left: 1px solid #b7b4b4;
  border-bottom: 0;
  border-top: 0;
  border-right: 0;

  margin-left: 15px;
`;

export const Rotate = styled.div`
  rotate: ${({ open }) => (open ? "-180deg" : "0deg")};

  transition: rotate 0.5s ease;
  margin-left: 8px;
`;

export const Situations = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 8px;

  padding: 0 16px;
`;

export const Status = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 10px;
`;

const mapColorChipToSituation = {
  SUCCESS: "#EAF4E0",
  WARNING: "#F9FFD9",
  DANGER: "#FFEBEB",
  DARK: "#EAE9E9",
};

export const Chip = styled.div`
  width: 110px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  gap: 8px;

  border-radius: 20px;

  align-items: center;

  justify-content: space-evenly;

  background-color: ${({ situation }) => mapColorChipToSituation[situation]};

  ${(props) => props.active ? `
    border: 1px solid rgba(0,0,0,0.2);
    transform: scale(1.1);
  ` : ''};
`;

export const StatusText = styled.p`
  font-size: 14;
  font-weight: bold;
  font-family: Poppins;
  margin-bottom: 0;
`;
