import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useFetch } from '../../hooks/useFetch';
import { Prefix } from '../../../services/prefix';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import Loading from '../../loader/loading';
import Fontawesome from '../../fontawesome/fontawesome';
import { Link } from 'react-router-dom';
import { mutate as GlobalMutate } from 'swr';

const EditContractOs = ({ id, perPage, page, search, idContract }) => {
  const [loader, setLoader] = useState();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const pageExact = parseInt(page) + 1;

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const osList = useFetch(Prefix.replace(/[\\"]/g, '') + '/os/' + id);

  const technicianList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/contract-technicians?all=true',
  );

  const outsourcedList = useFetch(
    Prefix.replace(/[\\"]/g, '') + '/third-companies?all=true',
  );

  useEffect(() => {
    setFormData({
      ...formData,
      id: id,
      id_third_company: osList.data?.model?.id_third_company,
    });
  }, [show]);

  function HandleChange(e) {
    if (e.target.name == 'id_technician') {
      setFormData({
        ...formData,
        id_technician: parseInt(e.target.value),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  }

  function HandleRealized(e) {
    setFormData({
      ...formData,
      realized: 1,
    });
  }

  function HandleResetThird() {
    setFormData({
      ...formData,
      id_third_company: null,
    });
  }

  function HandleRegister() {
    setLoader(<Loading />);
    api
      .put(Prefix.replace(/[\\"]/g, '') + '/os/' + formData?.id, formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow...',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
        setLoader();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, '') +
            '/os?all=false&per_page=' +
            perPage +
            '&page=' +
            pageExact +
            '&search=' +
            search,
        );
        setShow(false);
        osList.mutate();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
        setLoader();
      });
  }

  return (
    <>
      {!show ? (
        <Link
          className="dropdown-item link_color_datatable"
          to="#"
          onClick={handleShow}
        >
          <Fontawesome classe="fas fa-pencil-alt" />
        </Link>
      ) : (
        <Modal
          show={show}
          size={'lg'}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ordens de serviços</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="customerStep">
              {loader}

              <Row>
                <Col>
                  <label class="important">Agendamento para</label>
                  <input
                    type="datetime-local"
                    name="scheduled_at"
                    className="form-control"
                    onChange={HandleChange}
                    defaultValue={
                      osList.data?.model?.scheduled_at.split(' ')[0] +
                      'T' +
                      osList.data?.model?.scheduled_at.split(' ')[1]
                    }
                  />
                </Col>
                <Col>
                  <label class="important">Urgência</label>
                  <select
                    name="urgency"
                    className="form-control"
                    onChange={HandleChange}
                    defaultValue={osList.data?.model?.urgency}
                  >
                    <option disabled selected>
                      Selecione a urgência...
                    </option>
                    <option value="Baixa">Baixa</option>
                    <option value="Média">Média</option>
                    <option value="Alta">Alta</option>
                    <option value="Muito Alta">Muito Alta</option>
                  </select>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Observação</label>
                  <textarea
                    className="form-control"
                    name="obs"
                    onKeyUp={HandleChange}
                    defaultValue={osList.data?.model?.obs}
                  ></textarea>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button
                    variant="success"
                    className="ml-3"
                    onClick={HandleRegister}
                  >
                    Atualizar
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default EditContractOs;
