import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { BiCollapse } from "react-icons/bi";
import { CardPlant, CardSensor, Chip, Container, Situations, Status, StatusText } from "./index.style";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../../stores/inject";
import { MonitoringCard } from "../../../pages/sensorsDashboard/components/MonitoringCard";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Context } from "../../../../services/context";
import Fontawesome from "../../fontawesome/fontawesome";
import { MdZoomInMap } from "react-icons/md";
import { FaHouseSignal } from "react-icons/fa6";
import { IoExpand } from "react-icons/io5";
import { Center } from "../../../pages/sensorsDashboard/sensorDashboardModal";

const DetailPlan = ({ filter, expand, toggleExpand, openModalPlan, toggleModalPlan, contractItems }) => {
  const canvasRef = useRef(null);
  const { setViewSismogran, setNickname } = useContext(Context);
  const [popover, setPopover] = useState({
    visible: false,
    x: 0,
    y: 0,
    name: "",
    status: "",
    statusMessage: "",
    value: 0,
    max: 0,
    min: 0,
    measureName: "",
    typeSensor: "",
  });
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0.7); // Zoom inicial
  const [offset, setOffset] = useState({ x: 0, y: 0 }); // Deslocamento da imagem
  const [isDragging, setIsDragging] = useState(false); // Controle de arrasto
  const [initialMousePosition, setInitialMousePosition] = useState(null); // Posição inicial do mouse
  const { sensorStore } = useContext(StoreContext);
  const [filterPlan, setFilterPlan] = useState();


  const handleChipClickPlan = (situation) => {
    setFilterPlan((prevFilter) =>
      prevFilter === situation ? null : situation
    );
  };

  const reduceSituationsPlant = (equipments) => {
    const initial = {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0,
    };

    if (!Array.isArray(equipments)) return initial;

    return equipments?.reduce((acc, equipment) => {
      const sensors = equipment.monitor_profile_attruibutes;

      if (Array.isArray(sensors)) {
        let hasDark = false;
        let hasSuccess = false;
        let hasWarning = false;
        let hasDanger = false;

        sensors.forEach(sensor => {
          const status = sensor.status?.status;

          if (status) {
            if (status === "DARK") {
              hasDark = true;
            } else if (status === "SUCCESS") {
              hasSuccess = true;
            } else if (status === "WARNING") {
              hasWarning = true;
            } else if (status === "DANGER") {
              hasDanger = true;
            }
          }
        });

        if (hasDark) {
          acc.DARK += 1;
        } else if (hasSuccess) {
          acc.SUCCESS += 1;
        } else if (hasWarning) {
          acc.WARNING += 1;
        } else if (hasDanger) {
          acc.DANGER += 1;
        }
      }

      return acc;
    }, initial);
  };

  const situations = useMemo(() => {
    if (!contractItems || contractItems.length === 0) return {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0
    };

    return reduceSituationsPlant(contractItems);
  }, [contractItems]);


  const points = Array.isArray(contractItems)
  ? contractItems
      ?.map((room) =>
        Array.isArray(room.monitor_profile_attruibutes)
          ? room.monitor_profile_attruibutes
              ?.map((sensor) => {
                const sensorPoint = sensor.sensor_point;
                return {
                  x: sensorPoint?.x ?? 0,
                  y: sensorPoint?.y ?? 0,
                  status: room.situation,
                  statusMessage: sensor.status.message,
                  name: room.nickname,
                  sensorId: room.id,
                  value: sensor.value,
                  max: sensor.max,
                  min: sensor.min,
                  measureName: sensor?.profile_attribute?.unit_of_measure?.name,
                  typeSensor: sensor?.profile_attribute?.unit_of_measure.measure.name
                };
              })
              .filter((point) => point.x !== 0 || point.y !== 0) // Filtra os pontos indesejados
          : []
      )
      .flat()
  : [];


  const drawCanvas = useCallback(
    (image, ctx) => {
      if (!canvasRef.current || !ctx) return; // Verifica se canvas e contexto estão disponíveis

      const currentTime = Date.now();
      const opacity = 0.5 + 0.1 * Math.sin(currentTime / 100); // Animação de opacidade

      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Limpa o canvas
      ctx.globalAlpha = 1.0; // Define a opacidade animada

      const width = image.width * zoomLevel;
      const height = image.height * zoomLevel;

      ctx.drawImage(image, offset.x, offset.y + 100, width, height); // Desenha a imagem

      // Verifica se `points` é um array válido antes de aplicar `filter`
      if (Array.isArray(points)) {
        points
          .filter((point) => (filter ? point.status === filter : true))
          .filter((point) => (filterPlan ? point.status === filterPlan : true))
          .forEach((point) => {
            let color;
            switch (point.status) {
              case "SUCCESS":
                color = "green";
                break;
              case "DANGER":
                color = "red";
                break;
              case "WARNING":
                color = "orange";
                break;
              case "DARK":
                color = "gray";
                break;
              default:
                color = "black";
            }

            // Ajusta as coordenadas do ponto de acordo com o zoom e o tamanho da imagem no canvas
            const adjustedX = (point.x / image.width) * width + offset.x;
            const adjustedY = (point.y / image.height) * height + offset.y + 100; // Ajuste no Y para compensar o offset

            ctx.beginPath();
            ctx.arc(
              adjustedX,
              adjustedY,
              10 * zoomLevel,
              0,
              Math.PI * 2
            );
            ctx.fillStyle = color;
            ctx.globalAlpha = opacity; // Define a opacidade animada
            ctx.fill();
          });
      }
    },
    [filter, zoomLevel, offset, filterPlan, points] // Certifique-se de que `points` esteja incluído nas dependências
  );

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = sensorStore.selected?.unit_plant?.path;
    image.width = 1200;
    image.height = 760;

    const canvasWidth = image.width;
    const canvasHeight = image.height;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const animateCircles = () => {
      const animationFrameId = requestAnimationFrame(animateCircles);
      drawCanvas(image, ctx);
      return animationFrameId;
    };

    image.onload = () => {
      animateCircles();
    };

    return () => {
      cancelAnimationFrame(animateCircles);
    };
  }, [drawCanvas, expand, openModalPlan]);


  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    var calibration = 100;

    // Ajusta as coordenadas do mouse considerando o zoom e deslocamento
    const mouseX = (e.clientX - rect.left - offset.x) / zoomLevel;
    const mouseY = ((e.clientY + calibration) - (rect.top + calibration) - (offset.y + calibration)) / zoomLevel;

    let found = false;

    points
      .filter((room) => (filter ? room.status === filter : true))
      .forEach((point) => {
        // Mover o ponto para baixo de forma correta. Aqui estamos somando a posição Y.
        const adjustedY = point.y + calibration; // Muda o ponto 150 pixels para baixo (ajuste conforme necessário)

        // Calcular a distância entre o mouse e o ponto ajustado
        const distance = Math.sqrt(
          (mouseX - point.x) ** 2 + (mouseY - adjustedY) ** 2 // Aqui ajustamos a Y para descer
        );

        // Se a distância for menor que 20, mostramos o popover
        if (distance < 20) {
          setPopover({
            visible: true,
            x: e.clientX + 10,
            y: e.clientY + 10,
            name: point.name,
            status: point.status,
            statusMessage: point.statusMessage,
            value: point.value,
            max: point.max,
            min: point.min,
            measureName: point.measureName,
            typeSensor: point.typeSensor,
          });
          found = true;
        }
      });

    // Se nenhum ponto for encontrado, escondemos o popover
    if (!found) {
      setPopover({ visible: false, x: 0, y: 0, name: "", status: "" });
    }

    // Se estiver arrastando, atualiza o deslocamento
    if (isDragging) {
      const dx = e.clientX - initialMousePosition.x;
      const dy = e.clientY - initialMousePosition.y;
      setOffset({
        x: offset.x + dx,
        y: offset.y + dy,
      });
      setInitialMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleClick = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    var calibration = 100;

    // Ajusta as coordenadas do mouse considerando o zoom e deslocamento
    const mouseX = (e.clientX - rect.left - offset.x) / zoomLevel;
    const mouseY = ((e.clientY + calibration) - (rect.top + calibration) - (offset.y + calibration)) / zoomLevel;

    points
      .filter((room) => {
        if (filter) {
          return room.status === filter;
        }
        return true; // return true to keep all points if no filter is provided
      })
      .forEach((point) => {
        const adjustedY = point.y + calibration; // Muda o ponto 150 pixels para baixo (ajuste conforme necessário)


        const distance = Math.sqrt(
          (mouseX - point.x) ** 2 + (mouseY - adjustedY) ** 2 // Aqui ajustamos a Y para descer
        );

        if (distance < 20) {
          setViewSismogran(point.sensorId);
          setSelectedPoint(point);
          setNickname(point.name);
        }
      });
  };

  const handleWheel = (e) => {
    if (zoomLevel <= 0.5 && e.deltaY > 0) {
      return;
    }

    const zoomFactor = 0.1;
    let newZoomLevel = zoomLevel + (e.deltaY < 0 ? zoomFactor : -zoomFactor);

    newZoomLevel = Math.max(0.5, Math.min(newZoomLevel, 2));

    setZoomLevel(newZoomLevel);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setInitialMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const resetView = () => {
    setZoomLevel(0.7);
    setOffset({ x: 0, y: 0 });
  };




  return (

    <Modal
      show={openModalPlan}
      onHide={toggleModalPlan}
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex justify-content-between">
            <div role={"button"} style={{ display: "flex" }}>
              {openModalPlan && (
                <img
                  src="../../../media/icons/left-arrow.svg"
                  className="mr-4"
                  onClick={toggleModalPlan}
                  alt="Voltar"
                />
              )}{" "}
              <div style={{ display: "flex" }}>
                {/* <span style={{ marginRight: '10%' }}>Voltar</span> */}
                <Center>
                  {contractItems?.contractItems &&
                    contractItems?.contractItems[0]?.unit}
                </Center>
              </div>
            </div>
            <div className="d-flex align-self-center mt-2" style={{ marginRight: 20 }}>
              <FaHouseSignal size={27} />
              <p style={{ marginLeft: 10 }}>{sensorStore.selected?.name}</p>
            </div>

            <Situations>
              <Chip
                situation={"SUCCESS"}
                active={filterPlan === "SUCCESS"}
                onClick={() => {
                  if (situations.SUCCESS) handleChipClickPlan("SUCCESS");
                }}
              >
                <Status src="/media/icons/green-light.svg" />
                <StatusText>{situations.SUCCESS ?? "--"}</StatusText>
              </Chip>
              <Chip
                situation="WARNING"
                active={filterPlan === "WARNING"}
                onClick={() => {
                  if (situations.WARNING) handleChipClickPlan("WARNING");
                }}
              >
                <Status src="/media/icons/yellow-light.svg" />
                <StatusText>{situations.WARNING ?? "--"}</StatusText>
              </Chip>
              <Chip
                situation="DANGER"
                active={filterPlan === "DANGER"}
                onClick={() => {
                  if (situations.DANGER) handleChipClickPlan("DANGER");
                }}
              >
                <Status src="/media/icons/red-light.svg" />
                <StatusText>{situations.DANGER ?? "--"}</StatusText>
              </Chip>
              <Chip
                situation="DARK"
                active={filterPlan === "DARK"}
                onClick={() => {
                  if (situations.DARK) handleChipClickPlan("DARK");
                }}
              >
                <Status src="/media/icons/black-light.svg" />
                <StatusText>{situations.DARK ?? "--"}</StatusText>
                <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
              </Chip>
            </Situations>


            <Button onClick={toggleExpand}>
              <IoExpand size={27} />
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container expand={expand}>
          {expand &&
            <div className="d-flex justify-content-between p-4" style={{ width: '100vw' }}>
              <div className="d-flex align-self-center mt-2" style={{ marginRight: 20 }}>
                <FaHouseSignal size={27} />
                <p style={{ marginLeft: 10 }}>{sensorStore.selected?.name}</p>
              </div>
              <div className="d-flex justify-content-between" style={{ width: 500 }}>
                <Situations style={{ width: '100%' }}>
                  <Chip
                    situation={"SUCCESS"}
                    active={filterPlan === "SUCCESS"}
                    onClick={() => {
                      if (situations?.SUCCESS) handleChipClickPlan("SUCCESS");
                    }}
                  >
                    <Status src="/media/icons/green-light.svg" />
                    <StatusText>{situations?.SUCCESS || "--"}</StatusText>
                  </Chip>
                  <Chip
                    situation="WARNING"
                    active={filterPlan === "WARNING"}
                    onClick={() => {
                      if (situations?.WARNING) handleChipClickPlan("WARNING");
                    }}
                  >
                    <Status src="/media/icons/yellow-light.svg" />
                    <StatusText>{situations?.WARNING || "--"}</StatusText>
                  </Chip>
                  <Chip
                    situation="DANGER"
                    active={filterPlan === "DANGER"}
                    onClick={() => {
                      if (situations?.DANGER) handleChipClickPlan("DANGER");
                    }}
                  >
                    <Status src="/media/icons/red-light.svg" />
                    <StatusText>{situations?.DANGER || "--"}</StatusText>
                  </Chip>
                  <Chip
                    situation="DARK"
                    active={filterPlan === "DARK"}
                    onClick={() => {
                      if (situations?.DARK) handleChipClickPlan("DARK");
                    }}
                  >
                    <Status src="/media/icons/black-light.svg" />
                    <StatusText>{situations?.DARK || "--"}</StatusText>
                    <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                  </Chip>
                </Situations>
                <Button onClick={toggleExpand}>
                  <BiCollapse size={27} />
                </Button>
              </div>
            </div>
          }
          <div className="d-flex justify-content-center">
            <CardPlant
              expand={expand}
              onWheel={handleWheel}
              // style={{ position: "relative", cursor: "grab" }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setIsDragging(false)}
            >
              <div>
                <button
                  onClick={resetView}
                  style={{
                    position: "fixed",
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    left: 90,
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    zIndex: 20,
                    cursor: "pointer",
                  }}
                >
                  <MdZoomInMap size={26} />
                </button>
                <canvas
                  ref={canvasRef}
                  onClick={handleClick}
                  style={{
                    cursor: "pointer",
                    zIndex: 1,
                    borderRadius: 11,
                    alignSelf: "center",
                  }}
                />

                {popover.visible && (
                  <div
                    style={{
                      position: "absolute",
                      top: popover.y - 180,
                      left: popover.x - 80,
                      padding: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      borderRadius: "5px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                      pointerEvents: "none",
                      zIndex: 10,
                    }}
                  >
                    <strong>{popover.name} - {popover.typeSensor}</strong>
                    <p>{popover.statusMessage}</p>
                    <p><strong>Máximo:</strong> {popover.max}{popover.measureName}</p>
                    <p><strong>Leitura:</strong> {popover.value}{popover.measureName}</p>
                    <p><strong>Mínimo:</strong> {popover.min}{popover.measureName}</p>
                  </div>
                )}
              </div>
            </CardPlant>
            <CardSensor expand={expand}>
              {contractItems?.filter(x => filter ? x.situation === filter : true)
                .filter(x => filterPlan ? x.situation === filterPlan : true)
                .sort((a, b) => a.situation > b.situation ? -1 : 1)
                ?.map((item, index) => {
                  var newItem =
                  {
                    ...item,
                    monitor_environment: item.monitor_environment,
                    nickname: item.nickname,
                    sensors: item.monitor_profile_attruibutes
                  }
                  return <MonitoringCard md={0} item={newItem} key={index} />
                })}
            </CardSensor>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default observer(DetailPlan);