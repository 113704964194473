import { createContext } from "react";
import RoomStore from "../RoomStore";
import ContractItensStore from "../ContractItensStore";
import SensorStore from "../SensorStore";
import UnitStore from "../UnitStore";
import MonitotingHubConnectionStore from "../hubs/MonitotingHubConnectionStore";
import FloorPlanStore from "../FloorPlanStore";


export const StoreContext = createContext({
    roomStore: new RoomStore(),
    contractItensStore: new ContractItensStore(),
    sensorStore: new SensorStore(),
    unitStore: new UnitStore(),
    monitotingHubConnectionStore: new MonitotingHubConnectionStore(),
    floorPlanStore: new FloorPlanStore()
});