import React from 'react';
import { Link } from 'react-router-dom';
import '../contracts.css';
import ModalAttachments from './modalAttachments';
import ModalSchemes from './modalSchemes';
import IndexManagers from './indexManagers';
import ContractRenewal from './contractRenewal/contractRenewal';
import ContractEquipments from '../../contractsEquipments/contractsEquipments';
import Edit from '../editcontracts/indexEditContracts';
import Floorplan from '../floorplan';

const OptionsContracts = ({
  id,
  idCustomer,
  page,
  rowsPage,
  search,
  orderBy,
  order,
}) => {
  return (
    <div className="dropdown">
      <Link
        to="#"
        className="dropdown link_color_datatable"
        id="dropdownNotification"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={`/media/icons/elipse_options.svg`} width="5" />
      </Link>
      <ul
        className="dropdown-menu fade-in-top drop-options"
        aria-labelledby="dropdownNotification"
      >
        <li>
          <Edit
            id={id}
            page={page}
            rowsPage={rowsPage}
            search={search}
            orderBy={orderBy}
            order={order}
          />
        </li>
        <li>
          <ContractEquipments idContract={id} idCustomer={idCustomer} />
        </li>
        <li>
          <ModalAttachments
            iconClass="far fa-file-alt mr-2"
            text="Anexos do Contrato"
            idContract={id}
          />
        </li>
        <li>
          <ModalSchemes
            iconClass="fas fa-key mr-2"
            text="Licenças do Contrato"
            idContract={id}
          />
        </li>

        <li>
          <IndexManagers
            iconClass="fas fa-user-tie mr-2"
            text="Gestores do Contrato"
            idContract={id}
          />
        </li>
        <li>
          <ContractRenewal
            iconClass="fas fa-file-signature mr-2"
            text="Renovação de Contrato"
            idContract={id}
          />
        </li>
      </ul>
    </div>
  );
};
export default OptionsContracts;
