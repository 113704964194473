import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ButtonSave, CardWeeks, Container, ContentRoutine, EmptyList } from './styles/index.style';
import { TextField as MuiTextField } from '@material-ui/core';
import { LiaTemperatureHighSolid } from "react-icons/lia";
import Slider from '@material-ui/core/Slider';
import Swal from 'sweetalert2';
import { useRoutine } from '../../../hooks/routine.hook';
import Loading from "../../../components/loader/loading";
import { MdModeEdit } from "react-icons/md";
import { BsTrash } from "react-icons/bs";

const CardScheduling = ({ automation }) => {
  const { add, update, remove, loading } = useRoutine();
  const [selected, setSelected] = useState([]);
  const [automationData, setAutomationData] = useState(automation);
  const [editRoutine, setEditRoutine] = useState(null);

  const weeks = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];
  const weekMapping = {
    Seg: 'monday',
    Ter: 'tuesday',
    Qua: 'wednesday',
    Qui: 'thursday',
    Sex: 'friday',
    Sab: 'saturday',
    Dom: 'sunday',
  };

  const validationSchema = Yup.object().shape({
    init_time: Yup.string().required('Horário de ligar é obrigatório'),
    end_time: Yup.string().required('Horário de desligar é obrigatório'),
    setpoint: Yup.number()
      .min(100, 'Mínimo é 100')
      .max(250, 'Máximo é 250')
      .required('Temperatura é obrigatória'),
  });

  const toggleDaySelection = (day, values, setFieldValue) => {
    const nextSelected = selected.includes(day)
      ? selected.filter((item) => item !== day)
      : [...selected, day];

    setSelected(nextSelected);

    Object.keys(weekMapping).forEach((key) => {
      setFieldValue(weekMapping[key], nextSelected.includes(key) ? 1 : 0);
    });
  };

  // Atualiza initialValues do Formik quando editRoutine mudar
  useEffect(() => {
    if (editRoutine) {
      setSelected(Object.keys(weekMapping).filter(day => editRoutine[weekMapping[day]]));
    }
  }, [editRoutine]);

  const formatTime = (time) => {
    const parts = time.split(":");
    if (parts.length === 1) return `${parts[0].padStart(2, "0")}:00`;
    if (parts.length === 2) return `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`;
    return `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`;
  };
  
  const addItem = (routine) => {
    setAutomationData(prevState => ({
      ...prevState,
      automation_routines: [...prevState.automation_routines, routine]
    }));
    handleCancelEdit();
  };

  const handleAdd = (request) => {
    request.init_time = formatTime(request.init_time);
    request.end_time = formatTime(request.end_time);

    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Selecione algum dia da semana",
        confirmButtonText: "Tentar novamente",
      });
    } else {
      add(request, addItem);
    }
  };

  const handleUpdate = (request) => {
    const updatedRequest = {
      ...request,
      monday: selected?.includes('Seg') ? 1 : 0,
      tuesday: selected?.includes('Ter') ? 1 : 0,
      wednesday: selected?.includes('Qua') ? 1 : 0,
      thursday: selected?.includes('Qui') ? 1 : 0,
      friday: selected?.includes('Sex') ? 1 : 0,
      saturday: selected?.includes('Sab') ? 1 : 0,
      sunday: selected?.includes('Dom') ? 1 : 0,
    };

    updatedRequest.init_time = formatTime(updatedRequest.init_time);
    updatedRequest.end_time = formatTime(updatedRequest.end_time);

    const updatedRoutines = automationData.automation_routines.map(x =>
      x.id === updatedRequest.id ? updatedRequest : x
    );

    setAutomationData(prevState => ({
      ...prevState,
      automation_routines: updatedRoutines
    }));

    update(updatedRequest);
  };

  const removeItem = (id) => {
    const updatedRoutines = automationData.automation_routines.filter(x => x.id !== id);
    setAutomationData(prevState => ({
      ...prevState,
      automation_routines: updatedRoutines
    }));
    Swal.fire({
      icon: "success",
      title: "Excluído!",
      text: "O item foi excluído com sucesso.",
      confirmButtonText: "OK",
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Você deseja excluir?",
      text: "Esta ação não pode ser desfeita.",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(id, removeItem);
      }
    });
  };

  const renderListRoutine = (automation_routines) => {
    if (automation_routines.length === 0)
      return <EmptyList>Nenhuma rotina cadastrada</EmptyList>;

    return (
      automation_routines?.map((routine, i) =>
        <ContentRoutine key={i}>
          <div className='background'>
            <div className='d-flex justify-content-center dates'>
              <div className='header'>
                {(routine?.monday === true || routine?.monday === 1) && <p>Seg</p>}
                {(routine?.tuesday === true || routine?.tuesday === 1) && <><span className='bar' /> <p>Ter</p></>}
                {(routine?.wednesday === true || routine?.wednesday === 1) && <><span className='bar' /> <p>Qua</p></>}
                {(routine?.thursday === true || routine?.thursday === 1) && <><span className='bar' /> <p>Qui</p></>}
                {(routine?.friday === true || routine?.friday === 1) && <><span className='bar' /> <p>Sex</p></>}
                {(routine?.saturday === true || routine.saturday === 1) && <><span className='bar' /> <p>Sab</p></>}
                {(routine?.sunday === true || routine?.sunday === 1) && <><span className='bar' /> <p>Dom</p></>}

              </div>
            </div>
            <div className='d-flex justify-content-between p-3'>
              <div className='d-flex'>
                <p><strong>Ligar:</strong> {routine.init_time}</p>
                <span className='bar-bottom' />
                <p><strong>Desligar:</strong> {routine.end_time}</p>
                <span className='bar-bottom' />
                <p><strong>Set Point:</strong> {routine.setpoint}</p>
              </div>
              <div className='d-flex'>
                <MdModeEdit
                  className='icon-action'
                  onClick={() => {
                    setEditRoutine(routine);
                    setSelected(Object.keys(weekMapping).filter(day => routine[weekMapping[day]]));
                  }}
                />
                <BsTrash className='icon-action' onClick={() => handleDelete(routine.id)} />
              </div>
            </div>
          </div>
        </ContentRoutine>
      )
    );
  };

  const handleCancelEdit = () => {
    setEditRoutine(null);
    setSelected([]);
  };

  return (
    <Container>
      {loading && <Loading />}
      <Formik
        initialValues={{
          id_automation: automation.id,
          init_time: editRoutine ? editRoutine.init_time : '',
          end_time: editRoutine ? editRoutine.end_time : '',
          setpoint: editRoutine ? editRoutine.setpoint : 150,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
          sunday: 0,
        }}
        enableReinitialize // Faz com que o Formik se re-renderize quando editRoutine mudar
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (editRoutine) {
            values.id = editRoutine.id;
            handleUpdate(values);
          } else {
            handleAdd(values);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                {weeks.map((x) => (
                  <CardWeeks
                    key={x}
                    active={selected.includes(x)}
                    className="week"
                    onClick={() => toggleDaySelection(x, values, setFieldValue)}
                  >
                    {x}
                  </CardWeeks>
                ))}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <div className="d-flex">
                <div>
                  <Field
                    name="init_time"
                    as={MuiTextField}
                    label="Horário de ligar"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    style={{ background: '#fff', borderRadius: 7, marginRight: 20, width: 250 }}
                    value={values.init_time}
                  />
                  <ErrorMessage name="init_time" component="div" style={{ color: 'red' }} />
                </div>
                <div>
                  <Field
                    name="end_time"
                    as={MuiTextField}
                    label="Horário de desligar"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    style={{ background: '#fff', borderRadius: 7, width: 250 }}
                    value={values.end_time}
                  />
                  <ErrorMessage name="end_time" component="div" style={{ color: 'red' }} />
                </div>
              </div>
            </div>

            <div style={{ padding: 20 }}>
              <div className="d-flex" style={{ marginBottom: 20 }}>
                <LiaTemperatureHighSolid color="#3F4DCC" size={28} className="align-self-center" />
                <p style={{ fontSize: 16, alignSelf: 'center', margin: 0, marginLeft: 10 }}>
                  Temperatura estabelecida
                </p>
              </div>
              <div style={{ marginTop: 40, padding: 20 }}>
                <Slider
                  step={1}
                  min={100}
                  max={250}
                  value={values.setpoint}
                  valueLabelDisplay="on"
                  marks={[
                    { value: 100, label: '100' },
                    { value: 150, label: '150' },
                    { value: 200, label: '200' },
                    { value: 250, label: '250' },
                  ]}
                  onChange={(e, value) => {
                    setFieldValue('setpoint', value);
                    if (editRoutine) {
                      setEditRoutine(prev => ({
                        ...prev,
                        setpoint: value
                      }));
                    }
                  }}
                />
                <ErrorMessage name="setpoint" component="div" style={{ color: 'red' }} />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <ButtonSave disabled={loading} type="submit">
                {editRoutine ? "Atualizar" : "Cadastrar"}
              </ButtonSave>
              {editRoutine && (
                <ButtonSave type="button" onClick={handleCancelEdit} style={{ background: '#ccc', marginLeft: '10px' }}>
                  Cancelar
                </ButtonSave>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {renderListRoutine(automationData.automation_routines)}
    </Container>
  );
};

export default CardScheduling;
