import { makeAutoObservable, runInAction } from "mobx";
import Swal from "sweetalert2";
import { Prefix } from "../services/prefix";
import api from "../services/api";

class ContractItensStore {
    contracts;
    selected;
    loading

    constructor() {
        makeAutoObservable(this);
    }

    async getByUnit(id) {
        this.loading = true;
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/contract-items?&id_unit=${id}&monitoring=true`;
            const { data, status } = await api.get(path);

            runInAction(() => {
                if (status === 200 && data.model) this.selected = data.model;
                this.loading = false;
            })
        }
        catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
        }
        finally {
            this.loading = false;
        }
    }

    setSelected(object) {
        this.selected = object;
    }

    setSelectedSensor(object) {
        this.sensorSelected = object;
    }

}

export default ContractItensStore;