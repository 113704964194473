import { makeAutoObservable } from "mobx";
import Swal from "sweetalert2";
import { Prefix } from "../services/prefix";
import api from "../services/api";

class FloorPlanStore {
    sensorSelected;
    selected;
    loading;

    constructor() {
        makeAutoObservable(this);
    }

    async getByUnitId(id) {
        this.loading = true;
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/unit_plants?id_unit=${id}`;
            return await api.get(path);
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
            throw e;
        } finally {
            this.loading = false;
        }
    }

    setSelected(object) {
        this.selected = object;
    }
}

export default FloorPlanStore;