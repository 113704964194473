import { useState } from "react"
import RoutineAPI from "../api/RoutineAPI";
import Swal from "sweetalert2";


export const useRoutine = () => {
    const [loading, setLoading] = useState(false);

    const sendCommand = async (request, onAction) => {
        setLoading(true);
        try {
            const { data, status } = await RoutineAPI.action(request);
            if (status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Uow!",
                    text: "Comando enviado com sucesso",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    focusCancel: true,
                });
                onAction();
            }
        }
        catch (e) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Erro ao enviar comando",
                confirmButtonText: "Tentar novamente",
            });
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    }


    const add = async (request, addItem) => {
        setLoading(true);
        try {
            request.active = 1;
            const { data, status } = await RoutineAPI.add(request);
            if (status === 200) {
                addItem(data);
                Swal.fire({
                    icon: "success",
                    title: "Uow!",
                    text: "Cadastrado com sucesso",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    focusCancel: true,
                })
            }
        }
        catch (e) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Erro ao cadastrar",
                confirmButtonText: "Tentar novamente",
            });
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    }

    const update = async (request) => {
        setLoading(true);
        try {
            request.active = 1;
            const { status } = await RoutineAPI.update(request);
            if (status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Uow!",
                    text: "Atualizado com sucesso",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    focusCancel: true,
                })
            }
        }
        catch (e) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Erro ao atualizar",
                confirmButtonText: "Tentar novamente",
            });
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    }

    const remove = async (id, removeItem) => {
        setLoading(true);
        try {
            const { status } = await RoutineAPI.remove(id);
            if (status === 200) {
                removeItem(id);
            }
        }
        catch (e) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Erro ao excluir",
                confirmButtonText: "Tentar novamente",
            });
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading,
        sendCommand,
        add,
        update,
        remove
    }
}