import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Fontawesome from '../fontawesome/fontawesome';
import Modal from 'react-bootstrap/Modal';
import { Prefix } from '../../services/prefix';
import { useFetch } from '../hooks/useFetch';
import Loading from '../loader/loading';
import Alert from '../alert/alert';
import MUIDataTable from 'mui-datatables';
import columns from './columns';

export const IndexModalAlert = ({ id }) => {
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const alerts = useFetch(
    `${Prefix.replace(/[\\"]/g, '')}/clientes/alertas/${id}`,
  );

  if (alerts.error) {
    return (
      <Alert
        icon="error"
        title="Oops..."
        text={alerts.error.response.data.message}
        confirmButtonText="Fechar"
        logout={alerts.error.response.status == 401 ? 1 : 0}
      />
    );
  }
  if (!alerts.data) return <Loading />;
  alerts.mutate();

  const options = {
    filterType: 'dropdown',
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    viewColumns: false,
    searchPlaceholder: 'Pesquise um alerta',
    selectableRows: 'multiple',
    searchOpen: true,
    body: {
      noMatch: 'Desculpe, não encontramos cidades!',
      toolTip: 'Sort',
    },
    textLabels: {
      pagination: {
        next: 'Avançar',
        previous: 'Voltar',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },

      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Exibir colunas',
        titleAria: 'Exibir/Ocultar colunas da tabela',
      },
      selectedRows: {
        text: 'Coluna(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir linhas selecionadas',
      },
    },
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <Fontawesome classe="fas fa-exclamation-triangle mr-2" /> Alertas
      </Button>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alertas do Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customerStep">
            <div className="d-flex justify-content-between">
              <h2>Alertas</h2>
            </div>
          </div>
          <MUIDataTable
            data={alerts.data?.alerts?.data}
            columns={columns}
            options={options}
            components={{ Checkbox: () => false }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndexModalAlert;
