import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CardPlan = styled.div`
    width: 100%;
    /* background-color: #cbced1; */
    border-radius: 11px;
    margin: 10px;
`;
