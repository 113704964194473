import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import { Title, ButtonRange, AlertContainer, InputTime, ImgButton } from "./style";
import "./style";
import { useFetch } from "../../../../components/hooks/useFetch";
import { Prefix } from "../../../../services/prefix";
import "./style.css";
import Swal from "sweetalert2";
import api from "../../../../services/api";
import Loading from "../../../../components/loader/loading";
import { mutate as GlobalMutate } from "swr";
import { Context } from "../../../../services/context";
import { useEffect } from "react";
import Select from "react-select";
import { NoOptionsMessage } from "../../../../components/selectAsync/NoOptionsMessage";


const NotificationTimer = ({ HandleRange, data }) => {
  const { showNotificationTimer, setShowNotificationTimer } =
    useContext(Context);
  const [show, setShow] = useState(false)
  const [newValue, setNewValue] = useState();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    device: null,
    email: null,
    door: null
  });

  const timer = [
    { value: 5, label: "5 minutos" },
    { value: 15, label: "15 minutos" },
    { value: 30, label: "30 minutos" },
    { value: 45, label: "45 minutos" },
    { value: 60, label: "60 minutos" },
    { value: 0, label: "Silencioso" },
    { value: 3, label: "Padrão" }
  ];


  useEffect(() => {
    async function fetchMyTimer() {
      const dataUser = await api.get('user').then((response) => { return response })
      const dataTimer = await api.get(Prefix.replace(/[\\"]/g, "") +
        `/contract-item-notifications?id_user=${dataUser.data?.id}&id_contract_item=${data?.id_contract_item}`).then((r) => {
          setFormData(prevState => {
            return {
              ...prevState,
              device: r?.data?.models?.data[0]?.alert_notification
            }
          })
        });
      const dataEmail = await api.get(Prefix.replace(/[\\"]/g, "") + `/mail-notifications?id_contract_item=${data?.id_contract_item}`).then((r) => {
        setFormData(prevState => {
          return {
            ...prevState,
            email: r?.data?.models?.data[0]?.alert_notification

          }
        })
      })
      const dataDoor = await api.get(Prefix.replace(/[\\"]/g, "") + `/monitor-profile-attributes?id_contract_item=${data?.id_contract_item}`).then((r) => {
        setFormData(prevState => {
          return {
            ...prevState,
            door: r?.data?.models?.data[0]?.notification_door
          }
        })
        setShow(true)
      })
    }
    fetchMyTimer()
 
  // teste

  }, [showNotificationTimer]);


  function handleRegisterEmail(value) {
    setLoader(true);
    api
      .post(
        Prefix.replace(/[\\"]/g, "") +
        "/mail-notifications",
        {
          id_contract_item: data?.id_contract_item,
          active: 1,
          alert_notification: value
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Feito!",
          text: response.data.message,
          timer: 3000
        });
        setShowNotificationTimer(false);
        setLoader(false);
        setNewValue();
        HandleRange();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
          "/contract-items/" +
          data?.id_contract_item
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Desculpe...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  function handleRegisterDoor(value) {
    setLoader(true);
    api
      .put(
        Prefix.replace(/[\\"]/g, "") +
        "/notification-door/" + data?.id_contract_item,
        {
          notification_door: formData?.door
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Feito!",
          text: response.data.message,
          timer: 3000
        });
        setShowNotificationTimer(false);
        setLoader(false);
        setNewValue();
        HandleRange();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
          "/contract-items/" +
          data?.id_contract_item
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Desculpe...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  function handleRegister(value) {
    setLoader(true);
    api
      .post(
        Prefix.replace(/[\\"]/g, "") +
        "/contract-item-notifications",
        {
          alert_notification: value,
          id_contract_item: data?.id_contract_item
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Feito!",
          text: response.data.message,
          timer: 3000
        });
        setShowNotificationTimer(false);
        setLoader(false);
        setNewValue();
        HandleRange();
        GlobalMutate(
          Prefix.replace(/[\\"]/g, "") +
          "/contract-items/" +
          data?.id_contract_item
        );
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Desculpe...",
          text: err.response.data.message,
          confirmButtonText: "Tentar novamente",
        });
        setLoader(false);
      });
  }

  return (
    <>
      {showNotificationTimer && (
        <div className="customerStep">
          <AlertContainer variant="light">
            <Row>
              <Col>
                <Title>
                  <h3 className="teste">Configuração de Notificação</h3>
                </Title>

              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-3 text-center">
              <Col sm={4} md={4} lg={4} xl={4}>
                <label htmlFor="">Dispositivo</label>
                {show && (
                  <Select
                    closeMenuOnSelect={true}
                    placeholder="Selecione..."
                    defaultValue={timer.filter((filter) => filter.value == formData?.device)}
                    options={timer}
                    components={{ NoOptionsMessage }}
                    onChange={(e) => handleRegister(e?.value)}
                  />
                )}
              </Col>
              <Col sm={4} md={4} lg={4} xl={4}>
                <label htmlFor="">Email</label>
                {show && (
                  <Select
                    closeMenuOnSelect={true}
                    placeholder="Selecione..."
                    defaultValue={timer.filter((filter) => filter.value == formData?.email)}
                    options={timer}
                    components={{ NoOptionsMessage }}
                    onChange={(e) => handleRegisterEmail(e?.value)}
                  />
                )}
              </Col>
              <Col sm={4} md={4} lg={4} xl={4}>
                <label htmlFor="">Porta</label>
                <div className="formStep">
                  <div style={{ display: 'flex' }}>
                    {show && (
                      <InputTime
                        type="time"
                        defaultValue={formData?.door}
                        class="form-control"
                        step="1"
                        name="hora"
                        id="hora"
                        onBlur={(e) => {
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              door: e?.target?.value
                            }
                          })
                        }}
                      />
                    )}
                    <ButtonRange onClick={handleRegisterDoor} type="button">
                      <ImgButton src="/media/icons/svg/Communication/Write2.svg" />
                    </ButtonRange>
                  </div>
                </div>
              </Col>
            </Row>
          </AlertContainer>
          {/* <Row>
            <Col className="d-flex justify-content-center mt-4">

            </Col>
          </Row> */}
        </div>
      )}
    </>
  );
};
export default NotificationTimer;
