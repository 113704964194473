import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Loading from "../../../components/loader/loading";

import Sidebar from "../../../components/sidebar/sidebar";
import Topbar from "../../../components/topbar/topbar";

import "./sensorsDashboard.css";
import RoomMonitoring from "./components/RoomMonitoring";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../../components/hooks/useFetch";
import { Context } from "../../../services/context";
import DetailsMonitoring from "../../components/detailsMonitoring/detailsMonitoring";
import { AsyncPaginate } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../../components/selectAsync/NoOptionsMessage";
import { loadOptions } from "../../../components/selectAsync/loadOptions";
import { Content } from "./mapStyle";
import {
  Chip,
  LocationMonitoring,
  Status,
  StatusText,
} from "./components/RoomMonitoring/styles";
import { IoExpand } from "react-icons/io5";
import DetailPlan from "../../components/detailsMonitoring/detailsplan";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../stores/inject";
import { ButtonViewPlant, Center, Situations } from "./sensorDashboardModal";
import Fontawesome from "../../components/fontawesome/fontawesome";
import RoomMonitoringV2 from "./components/RoomMonitoringV2";
import { FaHouseSignal } from "react-icons/fa6";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import HandlerAlerts from "../../../components/handlerAlerts";

const SensorsDashboard = () => {
  const [full, setFull] = useState(true);
  const { viewSismogran, setViewSismogran } = useContext(Context);
  const { roomStore, monitotingHubConnectionStore, sensorStore, unitStore } =
    useContext(StoreContext);
  const [allRooms, setAllRoomsUnits] = useState();
  const [filter, setFilter] = useState();
  const [filterPlan, setFilterPlan] = useState();
  const [openModalPlan, setModalPlan] = useState(false);
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(true);
  const [valueSelect, setValueSelect] = useState([]);
  const [contractItems, setContractItems] = useState();

  const toggleModalPlan = () => setModalPlan(!openModalPlan);
  const toggleExpand = () => setExpand(!expand);

  const HandleSelect = (event, name) => {
    const updatedValueSelect = [...valueSelect]?.filter(
      (it) => it.nameField !== name
    );
    if (event) {
      updatedValueSelect.push({
        value: event.value,
        label: event.label,
        nameField: name,
      });
    }
    setValueSelect(updatedValueSelect);
  };



  const reduceSituations = (rooms) => {
    const statusCount = {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0,
    };
  
    const seenIds = new Set(); // Para armazenar os IDs já processados
  
    rooms.forEach((room) => {
      if (!seenIds.has(room.id)) { // Se o ID ainda não foi contado
        seenIds.add(room.id); // Adiciona ao Set para evitar repetição
  
        const status = room.situation;
          
        if (status && statusCount[status] !== undefined) {
          statusCount[status] += 1;
        }
      }
    });
  
    return statusCount;
  };

  const reduceSituationsPlant = (equipments) => {
    const initial = {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0,
    };

    return equipments?.reduce((acc, equipment) => {
      const sensors = equipment.monitor_profile_attruibutes;

      if (Array.isArray(sensors)) {
        sensors.forEach(sensor => {
          const status = sensor.status?.status; // Acessando diretamente o status

          if (status === "DARK") {
            acc.DARK += 1;
          } else if (status === "SUCCESS") {
            acc.SUCCESS += 1;
          } else if (status === "WARNING") {
            acc.WARNING += 1;
          } else if (status === "DANGER") {
            acc.DANGER += 1;
          }
        });
      }

      return acc;
    }, initial);
  };

  const situations = useMemo(() => {
    if (!sensorStore.selected || sensorStore.selected?.contractItems.length === 0) return null;

    return reduceSituationsPlant(sensorStore.selected?.contractItems);
  }, [sensorStore.selected]);

  const getValue = (nameField) =>
    valueSelect.find((it) => it.nameField === nameField)?.value || "";

  const idCustomer = getValue("id_customer");

  const idUnit = getValue("id_unit");

  const idSector = getValue("id_sector");

  const idRoom = getValue("id_room");



  useEffect(() => {
    setLoading(true);  // Inicializa o loading como true antes da requisição

    unitStore
      .getUnits(
        Prefix.replace(/[\\"]/g, "") +
        "/sismogran/map/units" +
        (idCustomer ? `?id_customer=${idCustomer}` : "")
      )
      .finally(() => {
        setLoading(false); // Apenas após a conclusão da requisição
      });
  }, [idCustomer]);

  const handleChipClick = (situation) => {
    setFilter((prevFilter) => (prevFilter === situation ? null : situation));
  };

  const handleChipClickPlan = (situation) => {
    setFilterPlan((prevFilter) =>
      prevFilter === situation ? null : situation
    );
  };

  // const units = useFetch(
  //   Prefix.replace(/[\\"]/g, "") +
  //   "/sismogran/map/units" +
  //   (idCustomer ? `?id_customer=${idCustomer}` : "")
  // );

  useEffect(() => {
    if (unitStore.units?.length > 0) {
      monitotingHubConnectionStore.connect.then((connection) => {
        connection.invoke("JoinGroup", `${unitStore.units[0].id_customer}`);
        connection.on("Sensors", (sensor) => {
          roomStore.updateSensor(sensor);
          const currentSelected = roomStore.selected || [];
          const contractItem = currentSelected.find(x => x.id === sensor.contractItemId);

          unitStore.updateSensorStatus(sensor);

          if (contractItem) {
            const monitorProfileAttributes = contractItem.monitor_profile_attruibutes?.find(mpa => mpa.id === sensor.sensorId);

            if (monitorProfileAttributes) {
              monitorProfileAttributes.status.status = sensor.status;
              monitorProfileAttributes.value = sensor.value;
              monitorProfileAttributes.readed_at = sensor.updatedAt;
              contractItem.situation = sensor.status;
              const updatedTime = new Date(sensor.updatedAt);
              updatedTime.setHours(updatedTime.getHours() + 3);
              contractItem.readed_at = updatedTime;

              const updatedContractItems = currentSelected.map(item =>
                item.id === contractItem.id
                  ? {
                    ...item,
                    monitor_profile_attruibutes: item.monitor_profile_attruibutes.map(mpa =>
                      mpa.id === monitorProfileAttributes.id
                        ? { ...mpa, status: monitorProfileAttributes.status, value: monitorProfileAttributes.value, readed_at: monitorProfileAttributes.readed_at }
                        : mpa
                    )
                  }
                  : item
              );

              setContractItems(updatedContractItems);
            }
          }
        });
      });
    } else {
      console.log("HUB: ERRO", "ERRO AO CONECTAR AO GRUPO");
    }
  }, [unitStore.loading]);


  const overallSituations = useMemo(() => {
    if (!allRooms || allRooms.length === 0) return null;

    const unitsAll = allRooms.flatMap(x => x.contractItems);
    return reduceSituations(unitsAll);
  }, [allRooms, unitStore.units]);



  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        {
          unitStore.units && <HandlerAlerts
            units={unitStore.units}
          />
        }
        <div id="content">
          <Topbar />
          <div className="content_all">
            {loading ? (
              <Loading />
            ) : (
              <Col className="card pb-3" style={{ borderRadius: 20 }}>
                <Row className="mt-4">
                  <Col
                    md={10}
                    className="d-flex justify-content-between filterMonit"
                  >
                    {["admin", "master"].includes(
                      Prefix.replace(/[\\"]/g, "")
                    ) && (
                        <AsyncPaginate
                          isClearable
                          value={valueSelect
                            .filter((fill) => fill.nameField === "id_customer")
                            .map((map) => {
                              return {
                                value: map.value,
                                label: map.label,
                              };
                            })}
                          loadOptions={loadOptions}
                          components={{ NoOptionsMessage }}
                          onChange={(e) => HandleSelect(e, "id_customer")} //values and name of select paginate
                          placeholder="Filtrar por cliente..."
                          cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                          classNamePrefix="filterMonitoring"
                          additional={{
                            page: 1,
                            endpoint: "/sismogran/map/customers",
                          }}
                        />
                      )}
                    <AsyncPaginate
                      isClearable
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_unit")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_unit")} //values and name of select paginate
                      placeholder="Filtrar por unidade..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/units",
                        params: idCustomer ? `id_customer=${idCustomer}` : "", //Separete with & (and commercial)
                      }}
                    />
                    <AsyncPaginate
                      isClearable
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_sector")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_sector")} //values and name of select paginate
                      placeholder="Filtrar por setor..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/sectors",
                        params: idUnit ? `id_unit=${idUnit}` : "", //Separete with & (and commercial)
                      }}
                    />

                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_room")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      isClearable
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_room")} //values and name of select paginate
                      placeholder="Filtrar por sala"
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/rooms",
                        params: `id_unit=${idUnit}&id_sector=${idSector}`, //Separete with & (and commercial)
                      }}
                    />
                  </Col>
                </Row>
                <Content>
                  <Col sm={12}>
                    {/* {
                      (unitStore.units?.length === 1
                        && unitStore.units[0].unit_plant !== null) &&
                      <ButtonViewPlant
                        onClick={() => {
                          if (unitStore.units?.length === 1) {
                            sensorStore.setSelected(
                              unitStore.units?.filter((unit) =>
                                idUnit ? idUnit === unit.id : true
                              )[0]
                            );
                            roomStore.setSelected(
                              unitStore.units?.filter((unit) =>
                                idUnit ? idUnit === unit.id : true
                              )[0]?.contractItems
                            );
                            toggleModalPlan();
                          }
                        }}
                      >
                        <LiaMapMarkedAltSolid className="align-self-center" size={24} />
                        <p style={{ margin: 0, marginLeft: 6, alignSelf: 'center' }}>Visualizar mapa</p>
                      </ButtonViewPlant>
                    } */}

                    <LocationMonitoring
                      style={{ justifyContent: "center", paddingLeft: 0 }}
                    >
                      <Chip
                        active={filter === "SUCCESS"}
                        situation="SUCCESS"
                        onClick={() => handleChipClick("SUCCESS")}
                      >
                        <Status src="/media/icons/green-light.svg" />
                        <StatusText>
                          {overallSituations?.SUCCESS || "--"}
                        </StatusText>
                        <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                      </Chip>

                      <Chip
                        active={filter === "WARNING"}
                        situation="WARNING"
                        onClick={() => handleChipClick("WARNING")}
                      >
                        <Status src="/media/icons/yellow-light.svg" />
                        <StatusText>
                          {overallSituations?.WARNING || "--"}
                        </StatusText>
                        <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                      </Chip>

                      <Chip
                        active={filter === "DANGER"}
                        situation="DANGER"
                        onClick={() => handleChipClick("DANGER")}
                      >
                        <Status src="/media/icons/red-light.svg" />
                        <StatusText>
                          {overallSituations?.DANGER || "--"}
                        </StatusText>
                        <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                      </Chip>

                      <Chip
                        active={filter === "DARK"}
                        situation="DARK"
                        onClick={() => handleChipClick("DARK")}
                      >
                        <Status src="/media/icons/black-light.svg" />
                        <StatusText>
                          {overallSituations?.DARK || "--"}
                        </StatusText>
                        <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                      </Chip>
                    </LocationMonitoring>
                  </Col>
                  {
                    unitStore.units
                      ? unitStore.units
                        .filter((unit) => (idUnit ? idUnit === unit.id : true))
                        .filter((unit) =>
                          filter
                            ? unit.contractItems.some((item) =>
                              item.situation === filter
                            )
                            : true
                        )
                        .map((it, index) => (
                          <RoomMonitoringV2
                            idUnit={it.id}
                            unit={it}
                            nameUnit={it.name}
                            key={index}
                            toggleModalPlan={toggleModalPlan}
                            idCustomer={idCustomer}
                            idSector={idSector}
                            idRoom={idRoom}
                            sensors={it.sensors}
                            customerName={it.customer}
                            setAllRoomsUnits={setAllRoomsUnits}
                            filter={filter}
                            openModalPlan={openModalPlan}
                            setContractItems={setContractItems}
                            contractItems={unitStore.units}
                          />
                        ))
                      : unitStore.units
                        ?.filter((unit) => (idUnit ? idUnit === unit.id : true)).map((it, index) => (
                          <RoomMonitoring
                            unit={it}
                            idUnit={it.id}
                            nameUnit={it.name}
                            key={index}
                            showNameUnit
                            idCustomer={idCustomer}
                            idSector={idSector}
                            idRoom={idRoom}
                            customerName={it.customer}
                            setAllRoomsUnits={setAllRoomsUnits}
                            filter={filter}
                          />
                        ))

                  }
                </Content>
              </Col>
            )}
          </div>
        </div >
      </div >

      {/* MODAL CARD */}
      < Modal
        show={!!viewSismogran}
        onHide={() => setViewSismogran()}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex justify-content-between">
              <div role={"button"}>
                {viewSismogran && (
                  <img
                    src="../../../media/icons/left-arrow.svg"
                    className="mr-4"
                    onClick={() => setViewSismogran()}
                    alt="Voltar"
                  />
                )}{" "}
                <>
                  Voltar
                  {viewSismogran && (
                    <Button
                      variant="success"
                      className="ml-4"
                      onClick={() => setFull(!full ? true : false)}
                    >
                      {full ? "Aumentar Visualização" : "Reduzir Visualização"}
                    </Button>
                  )}
                </>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailsMonitoring sensor={viewSismogran} full={full} />
        </Modal.Body>
      </Modal >

      {/* MODAL PLAN */}
      <DetailPlan
        // key={modalKey}  // Usa a chave dinâmica para forçar a re-renderização do modal
        full={full}
        filter={filterPlan}
        setFilter={setFilter}
        expand={expand}
        toggleExpand={toggleExpand}
        openModalPlan={openModalPlan}
        toggleModalPlan={toggleModalPlan}
        contractItems={contractItems}
      />
    </>
  );
};

export default observer(SensorsDashboard);
