import ScrollBar from 'react-perfect-scrollbar';
import styled from 'styled-components';

const mapColorChipToSituation = {
  SUCCESS: "#EAF4E0",
  WARNING: "#F9FFD9",
  DANGER: "#FFEBEB",
  DARK: "#EAE9E9",
};

export const Container = styled.div`
  ${({ expand }) => expand ?
    `
      background-color: #fff;
      position: fixed;
      z-index: 10;
      left: 0;
      top: 0;
      padding: 10;
      height: 100vh;
      width: 100vw;
    `
    :
    `
      max-height: 78vh;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden; 
  `}
`;

export const Chip = styled.div`
  width: 90px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  gap: 8px;

  border-radius: 20px;

  align-items: center;

  justify-content: space-evenly;

  background-color: ${({ situation }) => mapColorChipToSituation[situation]};

  ${(props) => props.active ? `
    border: 1px solid rgba(0,0,0,0.2);
    transform: scale(1.1);
  ` : ''};
`;

export const Status = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 10px;
`;

export const Situations = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 8px;

  padding: 0 16px;
`;

export const StatusText = styled.p`
  font-size: 14;
  font-weight: bold;
  font-family: Poppins;
  margin-bottom: 0;
`;


export const CardPlant = styled.div`
  background-color: #EBEBEB;
  border-radius: 11px;
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 55%;
  height: ${({ expand }) => expand ? '89vh' : 'auto'};


  ::-webkit-scrollbar {
    display: none;  /* Remove a barra de rolagem */
  }

  /* Para Firefox */
  scrollbar-width: none; 
`;

export const CardSensor = styled(ScrollBar)`
  padding: 15px;
  background-color: #EBEBEB;
  border-radius: 11px;
  overflow-y: scroll;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  min-width: ${({ expand }) => expand ? '30%' : '30%'};
  height: ${({ expand }) => expand ? '89vh' : '78vh'};
`;