import { HubConnection } from '@microsoft/signalr';
import { makeAutoObservable } from 'mobx';
import { connection } from '../../services/monitoring/hub';

class MonitotingHubConnectionStore {
  connect;
  error;
  state;

  constructor() {
    makeAutoObservable(this);
    this.connect = this.init();
    this.error = false;
  }

  async init() {
    const connectionHub = connection('service/hub');
    await connectionHub
      .start()
      .then(() => {
        this.error = false;
        this.state = connectionHub.state;
        console.log('>> system connected with hub service');
        connectionHub.onclose((error) => {
          connectionHub.start(); 
          console.error(`Something went wrong: ${error}`); 
        }); 
      })
      .catch((e) => {
        console.error('>> error connecting to hub service');
        this.error = true;
      });

    return connectionHub;
  }
}

export default MonitotingHubConnectionStore;