import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../customer.css';
import api from '../../../services/api';
import Fontawesome from '../../fontawesome/fontawesome';
import { Prefix } from '../../../services/prefix';
import Swal from 'sweetalert2';
import { Modal, Button, Alert, Row, Form, Col, Table } from 'react-bootstrap';
import { DropContainer, UploadMessage } from '../../customer/uploadFile';
import Dropzone from 'react-dropzone';

const CustomerUpload = ({ modalSize }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tabela, setTabela] = useState(0);
  const [resposta, setResposta] = useState(null);

  const [confirmation, setConfirmation] = useState(0);
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState();

  const handleExit = () => {
    setTabela(0);
  };

  const HandleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      name: file.name,
      readableSize: file.size,
      path: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(processUpload);

    setFormData(uploadedFiles[0]);

    setFormData2(uploadedFiles[0].file);
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append('file', uploadedFile.file);
    data.append('confirmation', confirmation);

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/clients-import', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        if (response.status == 200) {
          setResposta(response.data);
          setTabela(1);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };

  const handleRegister = () => {
    const data = new FormData();
    data.append('file', formData2);
    data.append('confirmation', 1);

    api
      .post(Prefix.replace(/[\\"]/g, '') + '/clients-import', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        });
      });
  };

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }

  return (
    <>
      <Button
        variant="secondary"
        style={{ marginRight: '5px' }}
        onClick={handleShow}
      >
        <Fontawesome classe="fas fa-file-excel mr-2" />
        Enviar Cliente CSV
      </Button>

      <Modal
        show={show}
        size={modalSize}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload de Cliente</Modal.Title>
          <a href="media/Demostracao.csv">
            <Button variant="success" style={{ marginLeft: '8px' }}>
              <Fontawesome classe="fas fa-file-excel mr-2" />
              Modelo
            </Button>
          </a>
        </Modal.Header>
        <Modal.Body>
          {tabela == 0 ? (
            <>
              <Row className="formStep">
                <Col>
                  <label className="important">Anexo:</label>
                  <Dropzone
                    maxFiles={1}
                    acceptedFiles={[
                      '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
                    ]} //"application/pdf, aplication/*"
                    onDrop={(acceptedFiles) => HandleUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                      >
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                      </DropContainer>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="formStep">
                <Col>
                  <label className="important">Clientes:</label>

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">Nome</th>
                        <th className="text-center">Razão</th>
                        <th className="text-center">Nome Fantasia</th>
                        <th className="text-center">Cidade</th>
                        <th className="text-center">UF</th>
                        <th className="text-center">Telefone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!resposta
                        ? ''
                        : resposta.models.map((map, key) => (
                            <tr key={key}>
                              <td className="text-center">{map.name}</td>
                              <td className="text-center">
                                {map.corporate_name}
                              </td>
                              <td className="text-center">
                                {map.fantasy_name}
                              </td>
                              <td className="text-center">{map.city.name}</td>
                              <td className="text-center">{map.city.uf}</td>
                              <td className="text-center">{map.phone1}</td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <div className="py-3 d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="mr-3"
                  onClick={handleRegister}
                >
                  Confirmar Envio
                </Button>
                <Button variant="secondary" onClick={handleExit}>
                  Cancelar
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CustomerUpload;
