import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from './index.style';
import AudioWarning from '../../assets/audio/warning1.mp3';

const HandlerAlert = ({ units }) => {
  const [alerts, setAlerts] = useState([]);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);

  useEffect(() => {
    const checkSensorStatus = () => {
      const newAlerts = [];

      units.forEach((unit) => {
        unit.contractItems.forEach((item) => {
          item.monitor_profile_attruibutes.forEach((attribute) => {
            if (attribute.status?.status === "DANGER") {
              newAlerts.push({
                sensorName: item.nickname,
                status: attribute.status.message
              });
              if (localStorage.getItem("@enableSound") && localStorage.getItem("@enableSound") === "on") {
                const audio = new Audio(AudioWarning);
                audio.play();
              }
            }
          });
        });
      });

      setAlerts(newAlerts);
    };

    const intervalId = setInterval(() => {
      if (units && localStorage.getItem("@enableAlert") && localStorage.getItem("@enableAlert") === "on") {
        checkSensorStatus(); // Verifica o status dos sensores a cada 5 segundos
      }
    }, 7000); // A cada 5 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
  }, [units]);

  useEffect(() => {
    let currentIndex = 0;

    const displayAlerts = () => {
      if (alerts.length > 0) {
        setCurrentAlertIndex(currentIndex);

        // Limpa a lista de alertas após 3 segundos
        setTimeout(() => {
          setAlerts([]); // Limpa os alertas após 3 segundos
        }, 2500); // 3 segundos para mostrar o alerta
        currentIndex = (currentIndex + 1) % alerts.length; // Avança para o próximo alerta
      }
    };

    // Executa a exibição de alertas a cada 3 segundos
    const alertInterval = setInterval(() => {
      displayAlerts();
    }, 3000); // A cada 3 segundos

    return () => clearInterval(alertInterval); // Limpa o intervalo quando o componente for desmontado
  }, [alerts]);

  return (
    <>
      {alerts.length > 0 ? (
        <Container>
          <h2>Alertas</h2>
          <div>
            <p className='title1'>Sensor</p>
            <p className='title1-sensor'>{alerts[currentAlertIndex]?.sensorName}</p>
          </div>
          <div>
            <p className='title2'>Mensagem</p>
            <p className='title2-status'>{alerts[currentAlertIndex]?.status}</p>
          </div>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default observer(HandlerAlert);
