import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;

    .title
    {
        font-size: 19pt;
        text-align: center;
        margin-top: 20px;
        color: #000;
    }

    .button-add
    {
        background-color: #852B2B;
        padding: 10px;
        border: 0;
        outline: none;
        margin-top: 40px;
        font-size: 15pt;
        font-weight: 600;
        color: #fff;
        align-self: center;
    }
`;

export const CardIcon = styled.div`
    height: 130px;
    width: 130px;
    padding: 30px;
    border-radius: 60px;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon
    {
        font-size: 55pt;
        color: #949090;
        align-self: center;
    }
`;