import api from "../services/api";
import { Prefix } from "../services/prefix";

class RoutineAPI {
    action = async (request) => await api.post(`${Prefix.replace(/[\\"]/g, "")}/automation/action`, request);
    add = async (request) => await api.post(`${Prefix.replace(/[\\"]/g, "")}/automation_routines`, request);
    update = async (request) => await api.put(`${Prefix.replace(/[\\"]/g, "")}/automation_routines/${request.id}`, request);
    remove = async (id) => await api.delete(`${Prefix.replace(/[\\"]/g, "")}/automation_routines/${id}`);
}

export default new RoutineAPI();