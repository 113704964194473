import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loadOptions } from '../../selectAsync/loadOptions';
import { colourStyles } from '../../selectAsync/colourStyles';
import { NoOptionsMessage } from '../../selectAsync/NoOptionsMessage';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Schema from './schema';
import { Context } from '../../../services/context';
import { Button, BackButton } from './addEquipmentsStyle';
import api from '../../../services/api';
import { Prefix } from '../../../services/prefix';
import { mutate as mutateGlobal } from 'swr';
import Swal from 'sweetalert2';
import { useFetch } from '../../hooks/useFetch';
import InputSpinner from 'react-bootstrap-input-spinner';

const AddEquipments = ({ back }) => {
  const { viewEquipments, setViewEquipments } = useContext(Context);
  const [registerData, setRegisterData] = useState({
    id_contract: viewEquipments.idContract,
    id_room: '',
    id_equipment_model: '',
    serial: '',
    out_of_contract: '',
    obs: '',
    out_of_use: '',
    alienated: '',
    additive: '',
    monitor_environment: null,
    active: 1,
    status: '',
    installation_date: '',
    instalation_responsable: '',
    preventive_frequency: 0,
    calibration_frequency: 0,
    criticity: '',
    patrimony: null,
    identification: '',
    nickname: '',
    important: '',
    id_equipment_category: '',
    id_sector: '',
    id_unit: '',
    monitoring: '',
    automation: false,
  });
  const [valueSelect, setValueSelect] = useState([]);

  const listProfiles = useFetch(
    registerData.id_equipment_mode == ''
      ? null
      : Prefix.replace(/[\\"]/g, '') +
      '/profile-attributes?id_equipment_model=' +
      registerData.id_equipment_model,
  );
  const profileData = listProfiles.data?.models?.data;

  const collectors = [
    { value: 1, name: 'Coletor de Ambiente' },
    {
      value: 0,
      name: 'Coletor de Equipamento',
    },
  ];

  const feature = [
    { value: 1, name: 'Sim, dar destaque' },
    {
      value: 0,
      name: 'Não dar destaque',
    },
  ];

  const criticity = [
    { value: 1, name: 'Apoio' },
    {
      value: 2,
      name: 'Rotina',
    },
    {
      value: 3,
      name: 'Crítico',
    },
  ];

  const additive = [
    { value: 1, name: 'Sim' },
    {
      value: 0,
      name: 'Não',
    },
  ];

  const allienated = [
    { value: 1, name: 'Sim' },
    {
      value: 0,
      name: 'Não',
    },
  ];

  const HandleSelect = (e, name) => {
    if (valueSelect.filter((fill) => fill.nameField == name).length > 0) {
      valueSelect
        .filter((fill) => fill.nameField == name)
        .map((map) => {
          let valueIndice = valueSelect.indexOf(map);
          valueSelect.splice(valueIndice);
        });

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
      if (name == 'id_equipment_category') {
        api
          .get(
            Prefix.replace(/[\\"]/g, '') +
            '/equipment-categories?id=' +
            e.value,
          )
          .then((r) => {
            setRegisterData({
              ...registerData,
              [name]: e.value,
              monitoring: r.data.models.data[0].equipment_family.monitoring,
            });
          });
      } else {
        setRegisterData({ ...registerData, [name]: e.value });
      }
    } else {
      if (name == 'id_equipment_category') {
        api
          .get(
            Prefix.replace(/[\\"]/g, '') +
            '/equipment-categories?id=' +
            e.value,
          )
          .then((r) => {
            setRegisterData({
              ...registerData,
              [name]: e.value,
              monitoring: r.data.models.data[0].equipment_family.monitoring,
            });
          });
      } else {
        setRegisterData({ ...registerData, [name]: e.value });
      }

      setValueSelect((prev) => {
        return [...prev, { value: e.value, label: e.label, nameField: name }];
      });
    }
  };

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == 'patrimony') {
      setRegisterData({ ...registerData, [name]: parseInt(value) });
    } else {
      setRegisterData({ ...registerData, [name]: value });
    }
  };

  const HandleRegister = () => {
    api
      .post(Prefix.replace(/[\\"]/g, '') + '/contract-items', registerData)
      .then((response) => {
        profileData.map((map) =>
          api.post(
            Prefix.replace(/[\\"]/g, '') + '/monitor-profile-attributes',
            {
              id_contract_item: response.data.model.id,
              min: map.min,
              max: map.max,
              active: 1,
              id_profile_attribute: map.id,
              safety_margin: map.safety_margin,
              safety_margin_time: map.safety_margin_time,
              variation: 0,
            },
          ),
        );

        Swal.fire({
          icon: 'success',
          title: 'Uow!',
          text: response.data.message,
          confirmButtonText: 'Fechar',
        }).then(() => {
          setViewEquipments({
            ...viewEquipments,
            addEquipments: false,
            equipments: true,
          });
          mutateGlobal(
            Prefix.replace(/[\\"]/g, '') +
            '/contract-items?id_contract=' +
            viewEquipments.idContract +
            '&per_page=15&page=1&search=&order_by=' +
            viewEquipments.orderBy +
            '&order=' +
            viewEquipments.order,
          );
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message,
          confirmButtonText: 'Tentar novamente',
        });
      });
  };  


  return (
    <Formik
      initialValues={registerData}
      validationSchema={Schema}
      onSubmit={HandleRegister}
      enableReinitialize
    >
      {({ values, errors }) => (
        <Form>
          <Row className="mt-2">
            <Col>
              <label>
                Unidade <span className="required">*</span>
              </label>
              <AsyncPaginate
                value={valueSelect
                  .filter((fill) => fill.nameField == 'id_unit')
                  .map((map) => {
                    return {
                      value: map.value,
                      label: map.label,
                    };
                  })}
                loadOptions={loadOptions}
                onChange={(e) => HandleSelect(e, 'id_unit')}
                placeholder="Selecione..."
                components={{ NoOptionsMessage }}
                cacheUniqs={[valueSelect]}
                styles={colourStyles}
                additional={{
                  page: 1,
                  endpoint: '/units',
                  params: `id_customer=${viewEquipments.idCustomer}`,
                }}
              />
              <ErrorMessage
                name="id_unit"
                component="span"
                className="errorMessages"
              />
            </Col>
            <Col>
              <label>
                Setor <span className="required">*</span>
              </label>
              <AsyncPaginate
                value={valueSelect
                  .filter((fill) => fill.nameField == 'id_sector')
                  .map((map) => {
                    return {
                      value: map.value,
                      label: map.label,
                    };
                  })}
                loadOptions={loadOptions}
                onChange={(e) => HandleSelect(e, 'id_sector')}
                placeholder="Selecione..."
                components={{ NoOptionsMessage }}
                cacheUniqs={[valueSelect]}
                styles={colourStyles}
                additional={{
                  page: 1,
                  endpoint: '/sectors',
                  params: `id_unit=${values.id_unit}`,
                }}
              />
              <ErrorMessage
                name="id_sector"
                component="span"
                className="errorMessages"
              />
            </Col>
            <Col>
              <label>
                Sala <span className="required">*</span>
              </label>
              <AsyncPaginate
                value={valueSelect
                  .filter((fill) => fill.nameField == 'id_room')
                  .map((map) => {
                    return {
                      value: map.value,
                      label: map.label,
                    };
                  })}
                loadOptions={loadOptions}
                onChange={(e) => HandleSelect(e, 'id_room')}
                placeholder="Selecione..."
                components={{ NoOptionsMessage }}
                cacheUniqs={[valueSelect]}
                styles={colourStyles}
                additional={{
                  page: 1,
                  endpoint: '/rooms',
                  params: `id_sector=${values.id_sector}`,
                }}
              />
              <ErrorMessage
                name="id_room"
                component="span"
                className="errorMessages"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col className="formStep">
                  <label>
                    Equipamento <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_equipment_category')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    onChange={(e) => HandleSelect(e, 'id_equipment_category')}
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: '/equipment-categories',
                    }}
                  />
                  <ErrorMessage
                    name="id_equipment_category"
                    component="span"
                    className="errorMessages"
                  />
                </Col>
                <Col className="formStep">
                  <label>
                    Automação <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField === 'automation')
                      .map((map) => ({
                        value: map.value,
                        label: map.value === true ? 'Sim' : 'Não',
                      }))[0] || { value: false, label: 'Não' }} 
                    loadOptions={() => Promise.resolve({
                      options: [
                        { value: true, label: 'Sim' },
                        { value: false, label: 'Não' },
                      ],
                    })}
                    onChange={(e) => HandleSelect(e, 'automation')}
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                  />
                  <ErrorMessage
                    name="id_equipment_category"
                    component="span"
                    className="errorMessages"
                  />
                </Col>
              </Row>
            </Col>
            {valueSelect.filter(
              (fill) => fill.nameField == 'id_equipment_category',
            ).length > 0 ? (
              <Col style={{ zIndex: '15' }}>
                <div className="formStep">
                  <label>
                    Modelo <span className="required">*</span>
                  </label>
                  <AsyncPaginate
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'id_equipment_model')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    loadOptions={loadOptions}
                    onChange={(e) => HandleSelect(e, 'id_equipment_model')}
                    placeholder="Selecione..."
                    components={{ NoOptionsMessage }}
                    cacheUniqs={[valueSelect]}
                    styles={colourStyles}
                    additional={{
                      page: 1,
                      endpoint: '/equipment-models',
                      params: `id_equipment_category=${values?.id_equipment_category}`,
                    }}
                  />
                  <ErrorMessage
                    name="id_equipment_model"
                    component="span"
                    className="errorMessages"
                  />
                </div>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="formStep">
                <label>
                  Serial<span className="required"> * </span>
                </label>
                <Field
                  type="text"
                  name="serial"
                  placeholder="Digite um serial"
                  onChange={HandleChange}
                />
                <ErrorMessage
                  name="serial"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label>
                  Data de Instalação <span className="required"> * </span>
                </label>
                <Field
                  as="input"
                  type="date"
                  name="installation_date"
                  onChange={HandleChange}
                />
                <ErrorMessage
                  name="installation_date"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            {registerData.monitoring == 1 && (
              <Col style={{ zIndex: '10' }}>
                <div className="formStep">
                  <label class="important">Tipo de coletor:</label>
                  <Select
                    value={valueSelect
                      .filter((fill) => fill.nameField == 'monitor_environment')
                      .map((map) => {
                        return {
                          value: map.value,
                          label: map.label,
                        };
                      })}
                    styles={colourStyles}
                    components={{ NoOptionsMessage }}
                    onChange={(e) => {
                      HandleSelect(e, 'monitor_environment')
                    }
                    }
                    placeholder="Selecione..."
                    options={collectors.map((map) => {
                      return { value: map.value, label: map.name };
                    })}
                  />
                  <ErrorMessage
                    name="monitor_environment"
                    component="span"
                    className="errorMessages"
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="formStep">
                <label>Responsável:</label>

                <AsyncPaginate
                  value={valueSelect
                    .filter(
                      (fill) => fill.nameField == 'instalation_responsable',
                    )
                    .map((map) => {
                      return {
                        value: map.value,
                        label: map.label,
                      };
                    })}
                  loadOptions={loadOptions}
                  onChange={(e) => HandleSelect(e, 'instalation_responsable')}
                  placeholder="Selecione..."
                  components={{ NoOptionsMessage }}
                  cacheUniqs={[valueSelect]}
                  styles={colourStyles}
                  additional={{
                    page: 1,
                    endpoint: '/customer-employees',
                    params: `id_customer=${viewEquipments.idCustomer}`,
                  }}
                />

                <ErrorMessage
                  name="instalation_responsable"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label class="important">Frequência de Preventiva:</label>
                <InputSpinner
                  type="number"
                  name="preventive_frequency"
                  variant="success"
                  size="md"
                  precision={2}
                  min={0}
                  value={
                    !registerData.preventive_frequency
                      ? 0
                      : registerData.preventive_frequency
                  }
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      ['preventive_frequency']: e,
                    })
                  }
                />

                <ErrorMessage
                  name="preventive_frequency"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label class="important">Frequência de Calibração:</label>

                <InputSpinner
                  type="number"
                  name="calibration_frequency"
                  variant="success"
                  size="md"
                  precision={2}
                  min={0}
                  value={
                    !registerData.calibration_frequency
                      ? 0
                      : registerData.calibration_frequency
                  }
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      ['calibration_frequency']: e,
                    })
                  }
                />
                <ErrorMessage
                  name="calibration_frequency"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label>
                  Destaque <span className="required"> * </span>
                </label>
                <Select
                  value={valueSelect
                    .filter((fill) => fill.nameField == 'important')
                    .map((map) => {
                      return {
                        value: map.value,
                        label: map.label,
                      };
                    })}
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={(e) => HandleSelect(e, 'important')}
                  placeholder="Selecione..."
                  options={feature.map((map) => {
                    return { value: map.value, label: map.name };
                  })}
                />
                <ErrorMessage
                  name="important"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="formStep">
                <label>
                  Apelido <span className="required"> * </span>
                </label>
                <Field type="text" name="nickname" onKeyUp={HandleChange} />
                <ErrorMessage
                  name="nickname"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label>
                  Criticidade <span className="required">* </span>
                </label>
                <Select
                  value={valueSelect
                    .filter((fill) => fill.nameField == 'criticity')
                    .map((map) => {
                      return {
                        value: map.value,
                        label: map.label,
                      };
                    })}
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={(e) => HandleSelect(e, 'criticity')}
                  placeholder="Selecione..."
                  options={criticity.map((map) => {
                    return { value: map.value, label: map.name };
                  })}
                />
                <ErrorMessage
                  name="criticity"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col>
              <div className="formStep">
                <label>
                  É um aditivo? <span className="required"> * </span>
                </label>
                <Select
                  value={valueSelect
                    .filter((fill) => fill.nameField == 'additive')
                    .map((map) => {
                      return {
                        value: map.value,
                        label: map.label,
                      };
                    })}
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={(e) => HandleSelect(e, 'additive')}
                  placeholder="Selecione..."
                  options={additive.map((map) => {
                    return { value: map.value, label: map.name };
                  })}
                />
                <ErrorMessage
                  name="additive"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={4}>
              <div className="formStep">
                <label>
                  Patrimônio <span className="required"> * </span>
                </label>
                <Field type="text" name="patrimony" onKeyUp={HandleChange} />
                <ErrorMessage
                  name="patrimony"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col sm={4}>
              <div className="formStep">
                <label>
                  Identificação <span className="required"> * </span>
                </label>
                <Field
                  type="text"
                  name="identification"
                  onKeyUp={HandleChange}
                />
                <ErrorMessage
                  name="identification"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
            <Col sm={4}>
              <div className="formStep">
                <label>
                  Alienado <span className="required">*</span>
                </label>
                <Select
                  value={valueSelect
                    .filter((fill) => fill.nameField == 'alienated')
                    .map((map) => {
                      return {
                        value: map.value,
                        label: map.label,
                      };
                    })}
                  styles={colourStyles}
                  components={{ NoOptionsMessage }}
                  onChange={(e) => HandleSelect(e, 'alienated')}
                  placeholder="Selecione..."
                  options={allienated.map((map) => {
                    return { value: map.value, label: map.name };
                  })}
                />
                <ErrorMessage
                  name="alienated"
                  component="span"
                  className="errorMessages"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-5 d-flex justify-content-end">
              {!viewEquipments.equipments && (
                <BackButton type="button" onClick={back}>
                  Voltar
                </BackButton>
              )}
            </Col>
            <Col className="mt-5 d-flex justify-content-start">
              <Button type="submit">Cadastrar</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default AddEquipments;
