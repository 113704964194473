import { makeAutoObservable } from "mobx";
import Swal from "sweetalert2";
import { Prefix } from "../services/prefix";
import api from "../services/api";

class RoomStore {
    room;
    sensorSelected;
    selected;

    constructor() {
        makeAutoObservable(this);
    }

    async getById(id) {
        try {
            const path = `${Prefix.replace(
                /[\\"]/g,
                ""
            )}/rooms/${id}`;
            const { data, status } = await api.get(path);
            if (status === 200 && data.model) this.selected = data.model;
        }
        catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao obter os dados',
                confirmButtonText: 'Fechar',
            });
        }
    }

    setSelected(object) {
        this.selected = object;
    }

    setSelectedSensor(object) {
        this.sensorSelected = object;
    }

    updateSensor(sensor) {
        const contractItem = this.selected?.find(x => x.id === sensor.contractItemId);
        
        if (contractItem) {
          const monitorPorfileAttributes = contractItem.monitor_profile_attruibutes?.find(mpa => mpa.id === sensor.sensorId);
          
          if (monitorPorfileAttributes) {
            // Atualizando os valores do sensor
            monitorPorfileAttributes.status.status = sensor.status;
            monitorPorfileAttributes.value = sensor.value;
            contractItem.situation = sensor.status;
      
            // Garantir a imutabilidade, criando um novo objeto para selected
            const updatedContractItems = this.selected?.map(item => 
              item.id === contractItem.id 
                ? { ...item, monitor_profile_attruibutes: [...item.monitor_profile_attruibutes] }
                : item
            );
      
            // Atualizando o estado de forma imutável
            this.setSelected(updatedContractItems); // Atualiza com um novo array de contractItems
          }
        }
      }
      
}

export default RoomStore;