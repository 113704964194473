import styled from 'styled-components';

const mapColorChipToSituation = {
  SUCCESS: "#EAF4E0",
  WARNING: "#F9FFD9",
  DANGER: "#FFEBEB",
  DARK: "#EAE9E9",
};

export const Chip = styled.div`
    width: 90px;
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    gap: 8px;
  
    border-radius: 20px;
  
    align-items: center;
  
    justify-content: space-evenly;
  
    background-color: ${({ situation }) => mapColorChipToSituation[situation]};
  
    ${(props) => props.active ? `
      border: 1px solid rgba(0,0,0,0.2);
      transform: scale(1.1);
    ` : ''};
  `;

export const Status = styled.img`
    width: 12px;
    height: 12px;
    margin-left: 10px;
  `;

export const Center = styled.p`
  font-size: 13pt;
  font-weight: bold;
  align-self: center;
  margin-top: 12px;
  margin-right: 30px;
`;

export const Situations = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
  
    align-items: center;
  
    gap: 8px;
  
    padding: 0 16px;
  `;

export const StatusText = styled.p`
    font-size: 14;
    font-weight: bold;
    font-family: Poppins;
    margin-bottom: 0;
  `;

export const ButtonViewPlant = styled.button`
    font-size: 14;
    font-family: Poppins;
    margin-bottom: 0;
    margin: 5px;
    margin-right: 20px;
    display: flex;
    padding: 8px;
    border-radius: 11px;
    background-color: #63330d;
    border: 0;
    color: #fff;
    transition: 0.2s;
`;