import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import App from "./App";

import NotFound from "./pages/404/NotFound";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import Maps from "./pages/maps/map";
import ProposalRequests from "./pages/proposals/proposalRequests/proposals";
import ProposalCustomer from "./pages/proposals/options/proposalCustomer";
/* Gestão */
import Dashboard from "./pages/dashboard/dashboard";
import DashboardClient from "./pages/dashboard/client/dashboardClient";
import Customer from "./pages/customer/customer";
import CustomerTeste from "./pages/customer/customerTeste";
import Users from "./pages/users/users";
import Employers from "./pages/employers/employers";
import Units from "./pages/units/units";
import Sectors from "./pages/sectors/sectors";
import Room from "./pages/room/room";
import Cities from "./pages/cities/cities";
import EquipamentsBrands from "./pages/equipaments_brands/equipamentsBrands";
import UnitsOfMeasures from "./pages/units_of_measures/unitsOfMeasures";
import Measures from "./pages/measures/measures";
import EquipamentsFamilies from "./pages/equipaments_families/equipamentsFamilies";
import Contracts from "./pages/contracts/contracts";
import ContractsTypes from "./pages/contractsTypes/contractsTypes";
import EquipamentsModel from "./pages/equipaments_model/equipamentsModel";
import EquipamentsCategory from "./pages/equipaments_category/equipamentsCategory";
import { AuthProvider } from "./services/context";
import { Context } from "./services/context";
import history from "./services/history";
import Loading from "./components/loader/loading";
import Schemes from "./pages/schemes/schemes";
import Modules from "./pages/modules/modules";
import Reports from "./pages/reports/reports";
import EquipamentsBackup from "./pages/equipaments_backup/equipamentsBackup";
import UserLog from "./pages/logs/logs";
import Notifications from "./pages/notifications/notifications";
import Registers from "./pages/registers/registers";
import Proposals from "./pages/proposals/proposals";
import Bases from "./pages/bases/bases";

/* Planejamento */
import Planning from "./pages/planning/planning";
import OsManagement from "./pages/os/os";
import OsServices from "./pages/os/servicesOs/servicesOs";

/* Sismogran */
import PortsMap from "./sismogran/pages/portsmap/portsmap";
import Schedules from "./sismogran/pages/schedules/schedules";
import Alerts from "./sismogran/pages/alerts/alerts";
import Dashboards from "./sismogran/pages/maps/map";
import Boards from "./sismogran/pages/boards/boards";
import BoardsComponents from "./sismogran/pages/boardscomponents/boardscomponents";
import Collectors from "./sismogran/pages/collectors/collectors";
import Calibrations from "./sismogran/pages/calibrations/calibrations";
import ActionPlan from "./sismogran/pages/actionPlan/actionPlan";
// import MonitoringTracks from './sismogran/pages/monitoringTracks/monitoringTracks';
import IndexEmployers from "./sismogran/pages/employers_client/indexEmployers";
import Notification from "./sismogran/pages/Notification/Notification";
import SensorsDashboard from "./sismogran/pages/sensorsDashboard/sensorsDashboard";
import { observer } from "mobx-react-lite";
import { StoreContext } from "./stores/inject";

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <Loading />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
}

const rotas = [
  //Públicas
  {
    path: "/gestao/propostas/verificar-propostas/:id/:count",
    component: ProposalCustomer,
  },

  //Gestão
  { path: "/mapa", component: Maps },
  { path: "/gestao/inicio", component: Dashboard },
  { path: "/gestao/cliente/inicio", component: DashboardClient },
  { path: "/gestao/clientes", component: Customer },
  { path: "/gestao/clientesTeste", component: CustomerTeste },
  { path: "/gestao/colaboradores", component: Employers },
  { path: "/gestao/usuarios", component: Users },
  { path: "/gestao/unidades", component: Units },
  { path: "/gestao/setores", component: Sectors },
  { path: "/gestao/salas", component: Room },
  { path: "/gestao/cidades", component: Cities },
  { path: "/gestao/marcas-equipamentos", component: EquipamentsBrands },
  { path: "/gestao/unidades-medidas", component: UnitsOfMeasures },
  { path: "/gestao/grandezas", component: Measures },
  { path: "/gestao/familias-equipamentos", component: EquipamentsFamilies },
  { path: "/gestao/contratos", component: Contracts },
  { path: "/gestao/tipoContrato", component: ContractsTypes },
  { path: "/gestao/modelos-equipamentos", component: EquipamentsModel },
  { path: "/gestao/categorias-equipamentos", component: EquipamentsCategory },
  { path: "/gestao/licenca", component: Schemes },
  { path: "/gestao/modulos", component: Modules },
  { path: "/gestao/relatorios", component: Reports },
  { path: "/gestao/equipamentos-backup", component: EquipamentsBackup },
  { path: "/gestao/historico", component: UserLog },
  { path: "/gestao/notificacoes", component: Notifications },
  { path: "/gestao/novos-cadastros", component: Registers },
  { path: "/gestao/bases", component: Bases },

  //Planejamento
  { path: "/planejamento/inicio", component: Planning },
  { path: "/planejamento/gestao-os", component: OsManagement },

  //Monitoramento
  { path: "/monitoramento/mapeamentoPortas", component: PortsMap },
  { path: "/monitoramento/agendamentos", component: Schedules },
  { path: "/monitoramento/alertas", component: Alerts },
  { path: "/monitoramento/inicio", component: SensorsDashboard },
  { path: "/monitoramento/map", component: Dashboards },
  { path: "/monitoramento/placasControle", component: Boards },
  { path: "/monitoramento/componentes", component: BoardsComponents },
  { path: "/monitoramento/coletores", component: Collectors },
  { path: "/monitoramento/calibracoes", component: Calibrations },
  { path: "/monitoramento/plano-acao", component: ActionPlan },
  {
    path: "/monitoramento/colaboradores-de-cliente",
    component: IndexEmployers,
  },
  { path: "/monitoramento/Notificacao", component: Notification },

  //Comercial
  { path: "/comercial/propostas", component: Proposals },
];

const Routes = () => {

  return (
    <AuthProvider>
      <Router history={history}>
        <Switch>
          <CustomRoute exact path="/" component={App} />
          <CustomRoute
            exact
            path="/recuperar-senha"
            component={ForgotPassword}
          />
          <CustomRoute
            exact
            path="/solicitacoes"
            component={ProposalRequests}
          />

          {rotas.map(({ path, component }, key) => (
            <CustomRoute
              isPrivate
              exact
              path={path}
              component={component}
              key={key}
            />
          ))}
          <CustomRoute component={NotFound} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default observer(Routes);