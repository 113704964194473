import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .title
    {
        font-size: 19pt;
        text-align: center;
        margin-top: 20px;
        color: #000;
    }

    .button-add
    {
        background-color: #852B2B;
        padding: 10px;
        border: 0;
        outline: none;
        margin-top: 40px;
        font-size: 15pt;
        font-weight: 600;
        color: #fff;
        align-self: center;
    }
`;

export const CardIcon = styled.div`
    height: 130px;
    width: 130px;
    padding: 30px;
    border-radius: 60px;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon
    {
        font-size: 55pt;
        color: #949090;
        align-self: center;
    }
`;

export const ButtonRemove = styled.button`
    background-color: #88393F;
    border: 0;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    width: 100%;
    color: #fff;
    margin-top: 10px;
`;

export const ScrollSensors = styled.div`
    overflow: auto;
    height: 30vh;
`;

export const ButtonSavePoint = styled.button`
    background-color: #f3890f90;
    border: 0;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    width: 100%;
    color: #fff;
`;
